import { useSelector } from "react-redux";
import useLabels from "../../hooks/useLabels";
import {
  selectActiveWinery,
  selectConfigs,
  selectUser,
} from "../../context/selectors";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CTA, Dropdown, Modal } from "../Generic";

import "./styles/index.scss";
import { lazy, Suspense, useEffect, useRef } from "react";
import { WineriesSelector } from "../WineriesList";
// import NextOperations from "./NextOperations";
import useAuth from "../../hooks/useAuth";
import { ROLES } from "../../constants/base";
// import AnalysisGrid from "./AnalysisGrid";
import { addMonths, format } from "date-fns";
import { getPDF } from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getItem } from "../../services/LocalStorage";
import { SkeletonAnalysisGrid, SkeletonNextOperations } from "../Skeleton/Skeleton";

const AnalysisGrid = lazy(() => import("./AnalysisGrid"));
const NextOperations = lazy(() => import("./NextOperations"));

const Home = ({ isMobile }) => {
  const [getLabel] = useLabels();
  const configs = useSelector(selectConfigs);
  const user = useSelector(selectUser);
  const ref = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const activeWinery = useSelector(selectActiveWinery);
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    if (location.hash.includes("wineriesList") && !ref?.current?.open) {
      ref?.current?.showModal();
    }
  }, []);

  useEffect(() => {
    if (!location.hash.includes("wineriesList") && ref?.current?.open) {
      ref?.current?.close();
    }

    if (activeWinery === null) {
      ref?.current?.close();
      ref?.current?.showModal();
    }
  }, [location.hash]);

  const winerySelectionCallback = () => {
    navigate(location.pathname);
    if (ref?.current?.open) {
      ref?.current?.close();
    }
  };

  const downloadDailyReport = async () => {
    const data = {
      pdf_type: "dailyReport",
      type: "operations",
      title: `${getLabel("printDailyExecutedOperation")} - ${new Date().toLocaleDateString("it-IT")}`,
      rows: null,
      columns: [
        getLabel("operationType"),
        getLabel("operationSrcTank"),
        getLabel("operationDestTank"),
        getLabel("operationCompletionUser"),
        getLabel("operationCompetionDate"),
      ],
      headers: [
        "type",
        "src_tanks",
        "dest_tanks",
        "completed_by",
        "completion_date",
      ],
      winery_name: activeWinery?.name || "",
      order_by: "expire_date",
      order_sort: "desc",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language:
        navigator.languages?.length > 0
          ? navigator.languages[0]?.replace("_", "-")
          : "it-IT",
    };

    await getPDF(
      data,
      axiosPrivate,
      getLabel("pdfType_DAILY_REPORT")
    );
  };

  const renderWelcome = () => {
    return (
      <div className="primary_container_upper_info">
        <h2>{user?.type !== 'TANK' ? getLabel("welcome", { username: user?.userName }) : getItem("currentMonitor") ? `${getLabel(user?.type)} ${getItem("currentMonitor")}` : ""}</h2>
        <h3>{getLabel("winery", { activeWinery: activeWinery?.name })}</h3>
        {role === ROLES.CLIENT && (<>
          <div className="primary_container_upper_info_row" style={{marginTop: '8%'}}>
            <Dropdown
              label={<>{getLabel("programOperation")}
              {" "}
              <FontAwesomeIcon icon="fa-calendar-plus" /></>}
              customClassName={'new_operation'}
              options={[
                {
                  label: getLabel("operationTypeDESTEMMING"),
                  handler: () => navigate("/programming/DESTEMMING/new"),
                },
                {
                  label: getLabel("operationTypeCUT"),
                  handler: () => navigate("/programming/CUT/new"),
                },
                {
                  label: getLabel("operationTypeRACKING"),
                  handler: () => navigate("/programming/RACKING/new"),
                },
                {
                  label: getLabel("operationTypeTREATMENT"),
                  handler: () => navigate("/programming/TREATMENT/new"),
                },
                {
                  label: getLabel("operationTypeANALYSIS"),
                  handler: () => navigate("/programming/ANALYSIS/new"),
                },
                {
                  label: getLabel("operationTypeDECANT"),
                  handler: () => navigate("/programming/DECANT/new"),
                },
                {
                  label: getLabel("operationTypeWINEMAKING"),
                  handler: () => navigate("/programming/WINEMAKING/new"),
                },
                {
                  label: getLabel("operationTypeWINEMAKINGPOST"),
                  handler: () => navigate("/programming/WINEMAKINGPOST/new"),
                },
                {
                  label: getLabel("operationTypeBOTTLING"),
                  handler: () => navigate("/programming/BOTTLING/new"),
                },
              ]}
            ></Dropdown>
          </div>
          <div className="primary_container_upper_info_row" style={{marginTop: '20px'}}>
            <CTA customClassName={'complete large'} onClick={downloadDailyReport}>
              {getLabel("printDailyExecutedOperation")}
              {" "}
              <FontAwesomeIcon icon="fa-print" />
            </CTA>
          </div>
          </>
        )}
      </div>
    );
  };

  const renderReminder = () => {
    return (
      <div className="primary_container_upper_info">
        <h4>{getLabel("nextOperations")}</h4>
        <h5>
          {getLabel("dateFrom") +
            " " +
            format(new Date(), configs.shortDateFormat) +
            " " +
            getLabel("dateTo") +
            " " +
            format(addMonths(new Date(), 1), configs.shortDateFormat)}
        </h5>

        <Card customClassName={'light_gray'}>
          <Suspense fallback={<SkeletonNextOperations rowCount={6} />}>
            <NextOperations hoverColor={'light_gray'} />
          </Suspense>
        </Card>
      </div>
    );
  };

  const renderLastAnalysis = () => {
    return (
      <Suspense fallback={<SkeletonAnalysisGrid title={getLabel("lastAnalysis")} cardCount={3} />}>
        <AnalysisGrid />
      </Suspense>
    );
  };

  const renderLegend = () => {
    return <div>
      <h5>{getLabel("legend")}</h5>
      <ul>
        <li><span>{getLabel("operationExpired")}</span><span className="icon">&#10071;</span></li>
        <li><span>{getLabel("operationExpiringExact")}</span><span className="icon"><FontAwesomeIcon icon="fa-calendar-day" /></span></li>
        <li><span>{getLabel("operationAnalysisToBeSampled")}</span><span className="icon"><FontAwesomeIcon icon="fa-eye-dropper" /></span></li>
        <li><span>{getLabel("operationAnalysisSampled")}</span><span className="icon"><FontAwesomeIcon icon="fa-flask-vial" /></span></li>
      </ul>
    </div>;
  };

  return (
    <section className="primary_container">
      <div className="primary_container_wrapper">
        <div className="primary_container_upper">
          {renderWelcome()}
          {renderReminder()}
        </div>
        <div className="primary_container_lower">{renderLastAnalysis()}</div>
        <div className="primary_container_lower legend">{renderLegend()}</div>
      </div>
      <Modal forwardRef={ref} customClassName={'is_winery_selector'}>
        <h4>{getLabel("wineriesModalSelector")}</h4>
        <WineriesSelector
          eventCallback={winerySelectionCallback}
          isSelector={true}
          isMobile={isMobile}
          isModal={true}
        />
      </Modal>
    </section>
  );
};

export default Home;
