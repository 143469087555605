import { useFieldArray, useForm, useWatch } from "react-hook-form";
import useLabels from "../../hooks/useLabels";
import {
  DatePicker,
  Input,
  Label,
  MultipleSelect,
  Select,
  Submit,
  TextArea,
} from "../FormComponents";
import {
  addDays,
  addHours,
  addMinutes,
  differenceInHours,
  eachDayOfInterval,
  roundToNearestMinutes,
} from "date-fns";
import { useNavigate, useParams } from "react-router";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useDispatch } from "react-redux";
import {
  newOperation,
  updateOperation,
} from "../../services/utils";
import {
  add,
  empty,
  update,
} from "../../context/operations/operationsSlice";
import { useEffect, useMemo, useRef, useState } from "react";
import Table from "../Table";
import SubstanceList from "../SubstanceList";
import { ConfirmationModal, OperationRecap, TankIcon } from "../Generic";
import { INT_REGEX } from "../../constants/regex";
import { toast } from "react-toastify";

const TanksRow = ({
  field,
  srcBatchFieldArray,
  idx,
  src_lots,
  lots,
  tanks,
  control,
  errors,
  srcTankEnabledFieldArray,
  setSrcTankEnabledFieldArray,
  columns,
  toggleSetStatusAll,
}) => {
  const [getLabel] = useLabels();
  const [key, setKey] = useState(field.id);

  const renderTable = (lot_id, filteredTanks) => {
    if (filteredTanks?.length > 0) {
      return (
        <>
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleSetStatusAll(lot_id, true);
              setKey((prevState) => {
                return { key: prevState.key + 1 };
              });
            }}
            type="button"
            id={"selectAll-" + lot_id}
          >
            <span className="sub"></span>
            {getLabel("selectAll")}
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleSetStatusAll(lot_id, false);
              setKey((prevState) => {
                return { key: prevState.key + 1 };
              });
            }}
            type="button"
            id={"unselectAll-" + lot_id}
          >
            <span className="sub"></span>
            {getLabel("unselectAll")}
          </button>
          <div className="tanks_list_table">
            <Table
              data={filteredTanks}
              prefix="analysisForm"
              customColumns={columns}
              isSearchBar={false}
            />
          </div>
        </>
      );
    }
  };

  return (
    <div key={field.id} className="operation_form_wrapper_row">
      <Select
        label={getLabel("operationLotName")}
        placeholder={getLabel("operationLotNamePlaceholder")}
        error={errors[`src_lots.${idx}`]}
        required={getLabel("inputRequiredError")}
        name={`src_lots.${idx}.lotData`}
        control={control}
        isSearchable={true}
        options={lots
          .filter(
            (lot) =>
              !srcBatchFieldArray?.fields?.some?.(
                (field) => field.lotData?.value == lot.id
              )
          )
          ?.map?.((lot) => {
            return {
              value: lot?.id,
              label:
                lot?.name +
                (lot?.state === "GRAPE"
                  ? ` (${getLabel("lotState_GRAPE")})`
                  : ""),
              isTank: tanks.filter((t) => t.batch_id == lot.id)?.length > 0,
            };
          })}
      />
      {src_lots[idx]?.lotData?.value &&
        renderTable(
          src_lots[idx]?.lotData.value,
          tanks
            .filter((t) => t.batch_id === src_lots[idx]?.lotData.value)
            ?.map((t) => ({
              id: t.id,
              name: t.name,
              quantity: t.quantity,
              batch_id: t.batch_id,
              idx: idx,
              enabled: srcTankEnabledFieldArray.includes(t.id) || false,
            }))
        )}
      {srcBatchFieldArray?.fields?.length > 1 && (
        <button
          onClick={(e) => {
            e.preventDefault();
            const tmpArray = srcTankEnabledFieldArray.filter(
              (el) =>
                !lots
                  .find(
                    (l) =>
                      l.id == srcBatchFieldArray?.fields[idx]?.lotData?.value
                  )
                  ?.tanks?.map((t) => t.tank_id)
                  .includes(el)
            );
            setSrcTankEnabledFieldArray(tmpArray);
            srcBatchFieldArray?.remove(idx);
          }}
        >
          -
        </button>
      )}
    </div>
  );
};

const AnalysisForm = ({
  type,
  operation,
  operations,
  protocols,
  allSubstances,
  activeWinery,
  tanks,
  users,
  configs,
  lots,
}) => {
  const { id } = useParams();
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isExactDate, setIsExactDate] = useState(false);
  const [substancesWithQuantity, setSubstancesWithQuantity] = useState([]);
  const [filteredSubstances, setFilteredSubstances] = useState([]);
  const [recursiveDates, setRecursiveDates] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  const [srcTankEnabledFieldArray, setSrcTankEnabledFieldArray] = useState([]);

  useEffect(() => {
    if (!protocols || protocols.length === 0) {
      return;
      // loadProtocols();
    }
    if (id && (!operations || operations?.length === 0)) return;  // loadOperations();
    if (id && operations?.length > 0) {
      const operation = operations?.find((o) => o.id === Number(id)) || null;
      if (operation === null) return;

      setValue("priority", {
        label: getLabel(`operationPriority${operation?.priority}`),
        value: operation?.priority,
      });

      const info = operation?.src_tanks?.[0]?.info || {};

      const srcs = [];
      const srcsBatch = [];
      operation?.src_tanks?.map((s) => {
        const tank = tanks?.find((t) => t.id === Number(s.tank_id));
        srcs.push({
          quantity: s?.quantity,
          tankData: {
            value: s?.tank_id,
            label: tank?.name,
            availability: Number(tank?.quantity),
            capacity: Number(tank?.capacity),
            quantity: Number(tank?.quantity),
            color: tank?.color,
            state: tank?.state,
            lot: tank?.batch_name || getLabel("tankState_EMPTY"),
            name: tank?.name + ` (${tank?.batch_name})`,
          },
        });
      });

      if (info?.analysisType === "lot") {
        const allTanksId = operation?.src_tanks?.map((s) => Number(s?.tank_id));
        const currentLots = lots?.filter((l) => l?.tanks?.some((tt) => allTanksId?.includes(Number(tt?.tank_id))));
        for (const currentLot of currentLots) {
          const tmp = {};
          for (const t of currentLot?.tanks) {
            tmp[t?.tank_id] = allTanksId?.includes(Number(t.tank_id));
          }
          srcsBatch.push(
            {
              "lotData": {
                ...tmp,
                "value": currentLot?.id,
                "label": currentLot?.name,
                "isTank": true
            },
          });
        }
        setSrcTankEnabledFieldArray(allTanksId);
        srcBatchFieldArray.replace(srcsBatch);
      }
      
      if (info?.analysisType === "tank")  srcTankFieldArray.replace(srcs);
      
      const cellarmans = [];
      operation?.cellarman_ids?.map((u) => {
        const user = users?.find((us) => us.id === u);
        cellarmans.push({
          value: u,
          label: user?.username,
        });
      });
      setValue("cellarman_ids", cellarmans);

      // repetitive form data
      setValue("protocolFrequency", {
        label: getLabel(info?.protocolFrequency === "repeat" ? "protocolRepeat" : "protocolSpot"),
        value: info?.protocolFrequency,
      });
      
      setValue("analysisType", {
        label: getLabel(info?.analysisType),
        value: info?.analysisType,
      });

      if (Number(operation?.total) > 1 && operation?.repetitive) {
        setValue("repeatSize", {
          label: getLabel(`repeatSize_${info?.repeatSize}`),
          value: info?.repeatSize,
        });
        setValue("repeatEnd", {
          label: getLabel(`repeatEnd_${info?.repeatEnd}`),
          value: info?.repeatEnd,
        });
        setValue("repeatDay", info?.repeatDay?.map((day) => { return {
          label: getLabel(`repeatDay_${info?.day}`),
          value: day,
        }}));

        setValue("repeat_start_date", new Date(info?.repeat_start_date));
        setValue("repeat_end_date", new Date(info?.repeat_end_date));
        setValue("repeatOccurrences", info?.repeatOccurrences);
        setValue("repeatEvery", info?.repeatEvery);
      }
      
      setValue('expire_date', new Date(operation?.expire_date));
      setValue("note", operation?.chat_notes?.[0]?.message || "");
      setIsExactDate(operation?.is_exact_date);
      const protocol = protocols?.find(
        (p) => p?.protocols_id === operation?.protocol_id
      );
      
      setValue("protocol_id", {
        value: operation?.protocol_id,
        label: protocol?.protocols_name,
        state: protocol?.state,
        color: protocol?.color,
      });
      
      buildProtocolsData();
    }
  }, [operation, tanks]);

  // const loadProtocols = async () => {
  //   const currentProtocols = await getProtocols(
  //     type?.toLowerCase(),
  //     axiosPrivate
  //   );
  //   dispatch(
  //     type?.toLowerCase() === "treatment"
  //       ? setTreatments(currentProtocols)
  //       : setAnalysis(currentProtocols)
  //   );
  // };

  const getFilteredSubstances = (substances = [], importDataState = null) => {
    if (type?.toLowerCase() === "treatment")
      return substances;
    else if (type?.toLowerCase() === "analysis") {
      const tmp = [];

      // Grouping function
      const grouped = substances
        ?.filter((s) => importDataState !== null ? s?.states?.includes(importDataState) : true)
        ?.reduce((acc, item) => {
          const key = item.substance_name || item.name;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item);
          
          return acc;
        }, {});

      // Convert the result to an array of grouped objects (optional)
      Object.keys(grouped)?.forEach((key) => {
        const items = grouped[key];
        const firstItem = items?.[0];
        const substance_name = getLabel(`substance_${key}`);
        tmp.push({
          name: key,
          substance_name: key,
          substance_name_lower: substance_name?.toLowerCase(),
          unit: firstItem?.unit,
          unit_id: firstItem?.unit_id,
          // unit: firstItem?.unit,
          id: firstItem?.id,
          value: firstItem?.id,
          label: substance_name,
          items: items,
          states: firstItem?.states,
          opType: "PROTOCOL",
        });
      });
      
      return tmp?.sort((a, b) =>
        a?.substance_name_lower > b?.substance_name_lower
          ? 1
          : b?.substance_name_lower > a?.substance_name_lower
          ? -1
          : 0
      );
    } else return [];
  };

  const buildProtocolsData = () => {
    const chosenProtocol = protocols?.find(
      (p) => p.protocols_id === protocol_id?.value
    );
    const substances =
      (type === "ANALYSIS"
        ? chosenProtocol?.analysis
        : chosenProtocol?.drugs) || [];

    const substancesWithQuantityTmp = [];
    // substances?.forEach((s) => {
    //   const index = chosenProtocol?.substance_ids?.indexOf(s.substance_id);
    //   // const isDisabled = operation?.treatments?.find((t) => t?.removed === true && s?.substance_id == t?.substance_id) !== undefined;
    //   if (index > -1)
    //     substancesWithQuantityTmp.push({
    //       ...s,
    //       quantity:
    //         (chosenProtocol?.quantities[index] !== -1
    //           ? chosenProtocol?.quantities[index]
    //           : null) || s?.quantity,
    //       opType: type,
    //     });
    // });
    if (type === "TREATMENT") {
      substances?.forEach((s) => {
        const currentTreatment =
          operation?.treatments?.find(
            (t) => t?.substance_id == s?.substance_id
          ) || null;
        const index =
          currentTreatment === null
            ? -1
            : chosenProtocol?.substance_ids?.indexOf(
                currentTreatment?.substance_id
              );
        if (index > -1 && id) {
          substancesWithQuantityTmp.push({
            ...s,
            quantity:
              currentTreatment?.quantity ||
              (chosenProtocol?.quantities[index] !== -1
                ? chosenProtocol?.quantities[index]
                : null) ||
              s?.quantity,
            opType: type,
            removed: currentTreatment?.removed === true,
          });
        } else if (index === -1) {
          substancesWithQuantityTmp.push({
            ...s,
            opType: type,
            removed: false,
          });
        }
      });
    } else if (type === "ANALYSIS")
      substancesWithQuantityTmp.push(...substances);

    const addedSubs = operation?.treatments?.filter((t) => t?.added === true) || [];
    for (const t of addedSubs) { 
      const sub = filteredSubstances?.find((s) => s?.id === t?.substance_id);
      substancesWithQuantityTmp.push({
        ...sub,
        substance_id: sub?.id,
        substance_name: sub?.name,
        quantity: t?.quantity || sub?.quantity,
        opType: type,
      });
    }
    setSubstancesWithQuantity(getFilteredSubstances(substancesWithQuantityTmp));
  };

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
    reset,
    setError,
    setValue,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      // priority: operation?.priority || "",
      // protocolFrequency: "",
      // expire_date: operation?.expire_date || null,
      type: "ANALYSIS",
      src_lots: operation?.src_lots || [
        { lotData: { value: null, label: null } },
      ],
      src_tanks: operation?.src_tanks || [
        { tankData: { value: null, label: null } },
      ],
      cellarman_ids: operation?.cellarman_ids || "",
    },
    mode: "onTouched",
  });

  const srcBatchFieldArray = useFieldArray({
    control,
    name: "src_lots",
  });

  const srcTankFieldArray = useFieldArray({
    control,
    name: "src_tanks",
  });

  const { protocol_id, src_lots, analysisType, src_tanks } = useWatch({ control });
  const previousProtocolIdRef = useRef();

  useEffect(() => {
    const protocol = protocols?.find(
      (p) => p?.protocols_id === protocol_id?.value
    ) || null;
    if (protocol_id?.value === null || protocol === null) return;

    const state = protocol?.state;
    
    const filteredSubs = getFilteredSubstances(allSubstances, state);
    setFilteredSubstances(filteredSubs);

    if (substancesWithQuantity?.length === 0 || (protocol_id?.value !== previousProtocolIdRef.current)) buildProtocolsData();

    // update the ref with the current protocol_id
    if (protocol_id !== previousProtocolIdRef.current) {
      previousProtocolIdRef.current = protocol_id?.value;
    }
  }, [protocol_id])
  
  const columns = useMemo(() => [
    {
      Header: getLabel("tankName"),
      accessor: "name",
    },
    {
      Header: getLabel("quantity") + (type === "TREATMENT" ? " (L)" : ""),
      accessor: "quantity",
    },
    {
      Header: getLabel("tankSelected"),
      accessor: (row) => (
        <Input
          name={`src_lots.${row.idx}.lotData.${row.id}`}
          register={register}
          label={getLabel(`operationSrcTankQuantity`)}
          placeholder={getLabel("operationSrcTanksQuantityPlaceholder")}
          error={errors[`src_tanks.${row.id}`]}
          //   required={getLabel("operationSrcTankQuantityRequiredError")}
          type={"checkbox"}
          isLabel={false}
          checked={row?.enabled}
          onChange={(e) => toggleTankEnabled([row.id], e.target.checked)}
        />
      ),
    },
  ]);

  const toggleTankEnabled = (tank_ids, checked) => {
    let tmpArray = srcTankEnabledFieldArray;
    tank_ids.forEach((tank_id) => {
      const found = tmpArray.includes(tank_id);
      if (!found && checked) {
        tmpArray.push(tank_id);
      } else if (found && !checked) {
        const foundIndex = tmpArray.indexOf(tank_id);
        if (foundIndex > -1) tmpArray = tmpArray.filter((t) => t != tank_id);
      }
    });

    setSrcTankEnabledFieldArray(tmpArray);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const customIdMissingDests = "custom-id-missing-dests";
  const customIdInvalidExpirDate = "custom-id-invalid-expire-date";
  const customIdInvalidState = "custom-id-invalid-state";

  const roundMinutes = (date) => {
    date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
    date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds

    return date;
  };

  const sameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  const getDayIndex = (value) => {
    switch (value) {
      case "MONDAY":
        return 1;
      case "TUESDAY":
        return 2;
      case "WEDNESDAY":
        return 3;
      case "THURSDAY":
        return 4;
      case "FRIDAY":
        return 5;
      case "SATURDAY":
        return 6;
      case "SUNDAY":
        return 7;
      default:
        return 0;
    }
  };

  const getHoursToAdd = (startDate, repeatEvery) => {
    const dailyEndDate = new Date(startDate)?.setHours(19, 0, 0);
    const hoursDiff = dailyEndDate.valueOf() - startDate.valueOf();
    if (hoursDiff < 0) return;
    const diffInHours = hoursDiff / 1000 / 60 / 60;
    const hoursToAdd = Math.round((diffInHours * 100) / repeatEvery) / 100;

    return hoursToAdd;
  };

  const openFormModal = () => {
    // trigger();

    const tmpDates = [];

    const {
      repeatEvery,
      repeatSize,
      repeatDay,
      repeatEnd,
      repeat_end_date,
      repeat_start_date,
      repeatOccurrences,
    } = getValues();

    const scaleFactor =
      repeatSize?.value === "HOUR"
        ? 1
        : repeatSize?.value === "DAILY"
        ? 1
        : repeatSize?.value === "DAY"
        ? 24
        : repeatSize?.value === "WEEK"
        ? 24 * 7
        : repeatSize?.value === "MONTH"
        ? 24 * 7 * 4
        : null;

    if (scaleFactor === null) return;

    const startDate = addHours(
      sameDay(repeat_start_date, new Date())
        ? new Date()
        : new Date(repeat_start_date)?.setHours(7, 0, 0),
      repeatSize?.value === "DAILY" ? 0 : repeatEvery * scaleFactor
    );
    const endDate =
      repeatEnd?.value === "DATE"
        ? addMinutes(
            new Date(repeat_end_date)?.setHours(23, 59, 59),
            -new Date(repeat_end_date).getTimezoneOffset()
          )
        : addDays(new Date(), 365);

    let i = 1;

    if (!["WEEK", "DAILY"].includes(repeatSize?.value)) {
      for (
        let newDate = startDate;
        newDate < endDate;
        newDate = addHours(newDate, repeatEvery * scaleFactor)
      ) {
        if (
          repeatSize?.value === "HOUR" &&
          (newDate.getHours() <= 7 || newDate.getHours() >= 19)
        )
          continue;
        tmpDates.push(
          repeatSize?.value === "HOUR"
            ? roundMinutes(newDate)
            : new Date(newDate.setHours(23, 59, 59))
        );
        i++;
        if (
          i > 100 ||
          tmpDates?.length >= 15 ||
          (repeatOccurrences && tmpDates?.length >= repeatOccurrences)
        )
          break;
      }
    } else if (repeatSize?.value === "DAILY") {
      // if isSameDayStart, the hoursToAdd is lower because some hours have already passed, so recompute it for the next day
      const isSameDayStart = sameDay(startDate, new Date());
      const hoursToAdd = getHoursToAdd(
        new Date(repeat_start_date)?.setHours(7, 0, 0),
        repeatEvery
      );
      const hoursToAddSameDay = getHoursToAdd(startDate, repeatEvery);

      const fakeDate =
        repeatEnd?.value === "OCCURRENCES" ? addDays(new Date(), 365) : null; // trick: fake date to have 'repeatOccurrences'
      const daysInterval = sameDay(startDate, new Date(repeat_end_date))
        ? [startDate]
        : eachDayOfInterval({
            start: new Date(startDate),
            end:
              repeatEnd?.value === "OCCURRENCES"
                ? fakeDate
                : new Date(repeat_end_date),
          });

      for (let i = 0; i < daysInterval?.length; i++) {
        let newDate = new Date(daysInterval?.[i]);
        const hours = isSameDayStart && i === 0 ? startDate.getHours() : 7;
        newDate.setHours(hours);
        let diffInHours = 0;
        if (isSameDayStart && i === 0) {
          const todayEnd = new Date(startDate);
          todayEnd.setHours(19);
          diffInHours = differenceInHours(todayEnd, startDate);
        }

        for (let j = 1; j <= repeatEvery; j++) {
          const newHours =
            hours +
            (isSameDayStart && i === 0 ? hoursToAddSameDay : hoursToAdd) * j;
          newDate.setHours(newHours);
          if (isSameDayStart && i === 0 && diffInHours > 0) {
            const minutes = newHours - Math.floor(newHours);
            newDate.setMinutes(minutes * 60);
            newDate = roundToNearestMinutes(newDate, {
              roundingMethod: "ceil",
              nearestTo: 10,
            });
          }
          if (
            repeatEnd?.value === "DATE" ||
            (repeatEnd?.value === "OCCURRENCES" &&
              Number(repeatOccurrences) > 0 &&
              tmpDates?.length < Number(repeatOccurrences))
          )
            tmpDates.push(new Date(newDate));
          // tmpDates.push(roundMinutes(newDate));
        }
      }
    } else {
      let newDate = addHours(
        new Date(repeat_start_date)?.setHours(23, 59, 59),
        repeatEvery * scaleFactor
      );
      while (i < 100 && newDate < endDate) {
        const element = repeatDay[0];
        const index = getDayIndex(element?.value);
        if (element <= 0 || element > 7) return;
        let d = newDate;
        d.setDate(d.getDate() + ((index + 7 - d.getDay()) % 7 || 7));
        i++;
        tmpDates.push(d);
        repeatDay?.forEach((el, idx) => {
          if (idx > 0) {
            const incrIndex = getDayIndex(el?.value);
            const incrDate = addDays(d, incrIndex - index);
            tmpDates.push(incrDate);
          }
        });
        newDate = addHours(newDate, repeatEvery * scaleFactor);

        if (
          tmpDates?.length >= 15 ||
          (repeatOccurrences && tmpDates?.length >= repeatOccurrences)
        )
          break;
      }
    }
    // console.log(tmpDates);
    setRecursiveDates(
      tmpDates
        ?.sort(function (d1, d2) {
          return d1 - d2;
        })
        ?.map((d) => {
          return {
            date: d,
            checked: true,
            format: ["HOUR", "DAILY"].includes(repeatSize?.value)
              ? configs.dateFormat
              : configs.mediumDateFormat,
          };
        })
    );

    // if (true === 0) {
    //   toast(
    //     getLabel("toast_destemmingMissingDests"),
    //     {
    //       toastId: customIdMissingDests,
    //       type: toast.TYPE.ERROR,
    //       isLoading: false,
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //     }
    //   );
    //   return;
    // } else {
    //   toast.dismiss(customIdMissingDests);
    // }

    // if (true) {
    //   toast(
    //     getLabel("toast_destemmingMissingLotSelectors"),
    //     {
    //       toastId: customIdMissingDests,
    //       type: toast.TYPE.ERROR,
    //       isLoading: false,
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //     }
    //   );
    //   return;
    // } else {
    //   toast.dismiss(customIdMissingDests);
    // }

    setIsOpen(true);

    // const totalLitersAvailable = Number(getValues("tank_id_in")?.quantity);
    // if (0 <= totalLitersAvailable) {
    //   toast.dismiss(customIdInvalidQuantities);
    //   setIsOpen(true);
    // } else {
    //   toast(
    //     getLabel("toast_transferInvalidQuantities", {
    //       sum: 0 || "0",
    //       total: totalLitersAvailable,
    //     }),
    //     {
    //       toastId: customIdInvalidQuantities,
    //       type: toast.TYPE.ERROR,
    //       isLoading: false,
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //     }
    //   );
    //   return;
    // }
  };

  const generateSrcTanks = (data) => {

    let isOnlyOneType = true;
    const isTank = data?.src_lots[0]?.lotData?.isTank; // isTank must be false/true for all the elements
    
    const src_tank_ids = [];

    if (analysisType.value === "lot") {
      for (const [s, ss] of Object.entries(data?.src_lots)) {
        if (isTank === null || isTank !== ss.lotData.isTank) {
          isOnlyOneType = false;
          break;
        }

        for (const [tank_id, selected] of Object.entries(ss.lotData)) {
          if (selected === true && Number.isInteger(Number(tank_id)) && isTank)
            src_tank_ids.push({ tank_id });
        }
        if (!isTank) src_tank_ids.push({ batch_id: ss.lotData.value });
      }

      srcTankEnabledFieldArray?.filter((t) => data?.src_lots?.some((s) => s?.lotData?.[t] === true))?.forEach((tank_id) => {
        if (!src_tank_ids?.find((t) => t.tank_id == tank_id)) {
          src_tank_ids.push({ tank_id });
        }
      });

      if (!isOnlyOneType) {
        console.log("Type error");
        return;
      }
    } else {
      src_tank_ids.push(...(
        data?.src_tanks?.map((s) => {
          return { tank_id: s.tankData.value };
        }))
      );
    }

    return src_tank_ids;
  }

  const submitForm = async (d) => {
    const data = d ? d : getValues();

    const expire_dates =
      data?.protocolFrequency?.value !== "repeat"
        ? []
        : recursiveDates
            ?.filter((d) => d?.checked)
            ?.map((d) =>
              addMinutes(
                new Date(d?.date),
                -new Date(d?.date).getTimezoneOffset()
              ).toISOString()
            );

    if (
      expire_dates?.length === 0 &&
      new Date(data?.expire_date?.setHours(23, 59, 59)) < new Date()
    ) {
      setError("expire_date", {
        type: "manual",
        message: getLabel("errorDateInThePast"),
      });
      return;
    }

    if (
      analysisType.value === "lot" &&
      (!Array.isArray(data?.src_lots) ||
        data?.src_lots?.length === 0 ||
        !data?.src_lots?.every(
          (lot) => lot?.lotData?.value && lot?.lotData?.label
        ))
    ) {
      toast(getLabel("toast_destemmingMissingDestsLots"), {
        toastId: customIdMissingDests,
        type: toast.TYPE.ERROR,
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    } else {
      toast.dismiss(customIdMissingDests);
    }

    const src_tank_ids = generateSrcTanks(data);

    // add form info when repetitive
    const info = data?.protocolFrequency?.value === 'repeat' ? {
      repeat_start_date: data?.repeat_start_date,
      repeat_end_date: data?.repeat_end_date,
      repeatDay: data?.repeatDay?.map((d) => d?.value),
      repeatEvery: data?.repeatEvery,
      repeatSize: data?.repeatSize?.value,
      repeatEnd: data?.repeatEnd?.value,
      repeatOccurrences: data?.repeatOccurrences,
    } : {};
    info.analysisType = data?.analysisType?.value;
    info.protocolFrequency = data?.protocolFrequency?.value;

    src_tank_ids[0] = { ...src_tank_ids[0], info: info };
    // console.log('info', info)
    // return
    if (
      analysisType.value === "lot" &&
      (src_tank_ids?.length === 0 ||
        !src_tank_ids?.every((tank) => tank?.tank_id))
    ) {
      toast(getLabel("toast_destemmingMissingDestsLots"), {
        toastId: customIdMissingDests,
        type: toast.TYPE.ERROR,
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    } else {
      toast.dismiss(customIdMissingDests);
    }

    for (let i = 0; i < src_tank_ids?.length; i++) {
      const t = tanks?.find((tt) => tt?.id == src_tank_ids?.[i]?.tank_id);
      if (Number(t?.quantity) <= 0 && data?.analysisType?.value === "tank") {
        setError(`src_tanks.${i}.tankData`, {
          type: "manual",
          message: getLabel("error0QuantityNewModify"),
        });
        return;
      }
    }

    let isStateOk = true;
    src_tank_ids?.forEach((_, index) => {
      isStateOk &= checkState(index);
    });
    if (!isStateOk) {
      return;
    }

    const requestPayload = {
      src_tanks: src_tank_ids,
      priority: data?.priority?.value,
      // ...(expire_dates?.length === 0 && { expire_date: addMinutes(
      //   new Date(data?.expire_date),
      //   -new Date(data?.expire_date).getTimezoneOffset()
      // ).toISOString() }),
      is_exact_date: expire_dates?.length === 0 ? isExactDate : false,
      ...(expire_dates?.length === 0 && {
        expire_date: new Date(
          data?.expire_date?.setHours(23, 59, 59)
        ).toISOString(),
      }),
      ...(expire_dates?.length > 0 && { expire_dates: expire_dates }),
      type: type,
      subtype: data?.analysisType?.value || "tank",
      winery_id: activeWinery?.id,
      protocol_id: protocol_id?.value,
      cellarman_ids: data?.cellarman_ids?.map((cellMan) => cellMan?.value),
      note: data?.note,
    };

    const treatments_data = [];
    const tmp_treatments_data = [];

    const currentProtocol = protocols?.find(
      (s) => s.protocols_id === protocol_id?.value
    );
    const removedSubIds = [];
    const addededSubIds = [];

    // initialize added/removed ids lists
    // console.log('data?.removed', data?.removed)
    if (typeof(data?.removed) === 'object' && Object.keys(data?.removed || {}).length > 0) {
      for (const [substance_id, isRemoved] of Object.entries(data?.removed)) {
        if (isRemoved?.value === true || isRemoved === true) removedSubIds.push(Number(substance_id));
        else if (isRemoved?.value === false || isRemoved === false) addededSubIds.push(Number(substance_id));
      }
    }

    if (!id) {   // set default substances
      currentProtocol?.[type === "TREATMENT" ? 'drugs' : 'analysis']?.forEach((s) => { 
        const substance = allSubstances?.find((ss) => ss?.id === Number(s?.substance_id));
        const val = Number(s?.quantity);
        const tmp = {
          substance_id: substance?.id,
          quantity: val,
          unit_id: substance?.unit_id,
        };
        tmp.removed = removedSubIds?.includes(substance?.id);

        const foundIndex = tmp_treatments_data?.findIndex((t) => t?.substance_id === (substance?.id));
        if (foundIndex > -1) tmp_treatments_data[foundIndex] = tmp;
        else tmp_treatments_data.push(tmp);
      });
    } else {
      const operation = operations?.find((o) => o.id === Number(id)) || null;
      operation?.treatments?.forEach((t) => {
        const tmp = { ...t };
        tmp.removed = removedSubIds?.includes(t?.substance_id);
        tmp_treatments_data.push(tmp);
      })
    }

    if (typeof(data?.column) === 'object' && Object.keys(data?.column || {}).length > 0) {
      for (const [substance_id, new_value] of Object.entries(data?.column)) {
        const substance = allSubstances?.find((s) => s?.id === Number(new_value?.substance_id || substance_id));
        if (substance !== null) {
          const foundIndex = tmp_treatments_data?.findIndex((t) => t?.substance_id === (substance?.substance_id || substance?.id));
          const val =
          Number(new_value?.new) >= 0
            ? Number(new_value?.new)
            : substance?.quantity || 0;

            const tmp = {
            ...(foundIndex > -1 && { ...tmp_treatments_data[foundIndex] }),
            substance_id: substance?.substance_id || substance?.id,
            quantity: val,
            unit_id: substance?.unit_id,
            ...(removedSubIds?.includes(substance?.id) && { removed: true }),
            ...(addededSubIds?.includes(substance?.id) && { added: true }),
          };

          if (foundIndex > -1) {
            tmp_treatments_data[foundIndex] = tmp;
          } else if (tmp?.substance_id && (type === "ANALYSIS" || (type === "TREATMENT" && val > 0)))
            tmp_treatments_data.push(tmp);
        }
      }   
    }

    if (type === "TREATMENT")
      treatments_data.push(...tmp_treatments_data);
    else {
      treatments_data.push(...tmp_treatments_data?.filter((t) => t?.removed === true)?.map((t) => { return { substance_id: t?.substance_id, removed: t?.removed }}));
      treatments_data.push(...tmp_treatments_data?.filter((t) => t?.added === true)?.map((t) => { return { substance_id: t?.substance_id, added: t?.added }}));
    }
    // console.log('tmp_treatments_data', tmp_treatments_data)
    
    // return;
    requestPayload.treatments_data = treatments_data;
    console.log("treatments_data", requestPayload?.treatments_data);

    // console.log("requestPayload", requestPayload);
    // return;
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });
    let response = null;

    if (id) {
      response = await updateOperation(id, requestPayload, axiosPrivate);
    } else {
      response = await newOperation(requestPayload, axiosPrivate);
    }
    console.log("response", response);

    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { type: operation?.type || "" })
        : getLabel(response?.error),
      type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });
    if (response && response?.success) {
      dispatch(empty());
      navClickHandler();
    }
  };

  const updateStore = (payload) => {
    if (payload.id) {
      dispatch(update(payload));
    } else {
      dispatch(add(payload));
    }
  };

  const navClickHandler = () => {
    if (id) {
      navigate(`/programming/${type}/${id}`);
    } else {
      navigate("/programming");
    }
  };

  const toggleSetStatusAll = (lot_id, enableStatus = false) => {
    const tank_ids =
      tanks.filter((t) => t.batch_id === lot_id)?.map((t) => t.id) || [];
    toggleTankEnabled(tank_ids, enableStatus);
  };

  const checkState = (idx) => {
    try {
      const new_tank = ((analysisType.value === "lot") ? getValues(`src_lots.${idx}.lotData`) : getValues(`src_tanks.${idx}.tankData`)) || null;
      if (!new_tank || !new_tank?.state) {
        throw new Error('Error retrieving tank data');
      }
      if (type === 'ANALYSIS') {
        for (const s of substancesWithQuantity) {
          const allowedStates = s?.states || null;
          if (allowedStates === null || allowedStates?.length === 0) {
            throw new Error("Error retrieving substance's states");
          }
          if (!allowedStates?.includes(new_tank?.state)) {
            const allowedStatesString = allowedStates?.reduce((accumulator, current) => {
              return accumulator + (accumulator ? ', ' : '') + getLabel(`lotState_${current}`);
            }, '');
            toast.error(
              getLabel("toast_analysisProtocolInvalidTankState", { name: s?.substance_name_lower, tank_name: new_tank?.label, states: allowedStatesString?.toLowerCase(), state: getLabel(`lotState_${new_tank?.state}`)?.toLowerCase()}),
              {
                toastId: customIdInvalidState,
                isLoading: false,
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
            return false;
          }
        }
        return true;
      }
    } catch (err) {
      // console.error(err.message);
      return false;
    }
  }

  return (
    <div className="operation_form_wrapper">
      <form
        onSubmit={handleSubmit(
          getValues("protocolFrequency")?.value === "repeat"
            ? openFormModal
            : submitForm
        )}
        autoComplete="off"
        noValidate
      >
        <h4>{type === 'ANALYSIS' ? getLabel("analysisConfiguration") : getLabel("treatmentConfiguration")}</h4>
        <div className="operation_form_wrapper_row">
          <Select
            name="protocol_id"
            label={getLabel("protocolName")}
            placeholder={getLabel("protocolNamePlaceholder")}
            control={control}
            error={errors}
            required={getLabel("inputRequiredError")}
            options={protocols?.map?.((p) => {
              return {
                value: p?.protocols_id,
                label: p?.protocols_name,
                color: p?.color,
                state: p?.state,
              };
            })}
            onTableChange={() => {           
              setValue("column", []);
              setValue("removed", []);
              setValue("quantities", []);
              buildProtocolsData();
            }}
          />
          {protocol_id?.value && type?.toLowerCase() === "analysis" && (
                <div className="protocol_info_container_row">
                  <Label label={getLabel("analysisState")}>
                    {protocol_id?.state ? getLabel(`lotState_${protocol_id?.state}`) : ""}
                  </Label>
                </div>
              )}
          </div>
        <div className="operation_form_wrapper_row">
          {protocol_id?.value && (
            <>
              <SubstanceList
                isProtocolApplication={true}
                register={register}
                errors={errors}
                control={control}
                substanceType={
                  type?.toLowerCase?.() === "treatment"
                    ? "substance"
                    : "analysis"
                }
                substances={substancesWithQuantity}
                allSubstances={filteredSubstances}
                // allSubstances={allSubstances}
                isSearchBar={false}
                setValue={setValue}
                getValues={getValues}
                isCheckbox={true}
                disabledIds={operation?.treatments || []}
              />
            </>
          )}
        </div>
        <h4>{getLabel("operationTanksConfiguration")}</h4>
        <div className="operation_form_wrapper_row vertical">
          <Select
            label={getLabel("operationAnalysisTypeName")}
            placeholder={getLabel("operationAnalysisTypeNamePlaceholder")}
            error={errors}
            name="analysisType"
            control={control}
            required={getLabel("inputRequiredError")}
            options={[
              {
                label: getLabel("lot"),
                value: "lot",
              },
              {
                label: getLabel("tank"),
                value: "tank",
              },
            ]}
            tooltip={getLabel("analysisSourceTypeTooltip")}
          />
          {analysisType?.value === "lot" && (
            <>
              {srcBatchFieldArray?.fields?.map?.((field, idx) => {
                return (
                  <TanksRow
                    field={field}
                    idx={idx}
                    src_lots={src_lots}
                    srcTankEnabledFieldArray={srcTankEnabledFieldArray}
                    setSrcTankEnabledFieldArray={setSrcTankEnabledFieldArray}
                    srcBatchFieldArray={srcBatchFieldArray}
                    tanks={tanks}
                    lots={lots}
                    errors={errors}
                    control={control}
                    columns={columns}
                    toggleSetStatusAll={toggleSetStatusAll}
                  />
                );
              })}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  srcBatchFieldArray?.append({
                    lotData: {
                      value: null,
                      label: null,
                    },
                  });
                }}
              >
                +
              </button>
            </>
          )}
        </div>
        {analysisType?.value === "tank" && (
          <>
            <div className="operation_form_wrapper_row vertical">
              {srcTankFieldArray?.fields?.map?.((field, idx) => {
                return (
                  <div key={field.id} className="operation_form_wrapper_row">
                    <Select
                      label={getLabel("operationSrcTankName")}
                      placeholder={getLabel("operationSrcTanksPlaceholder")}
                      error={errors}
                      required={getLabel("inputRequiredError")}
                      name={`src_tanks.${idx}.tankData`}
                      isSearchable={true}
                      control={control}
                      options={tanks
                        .filter(
                          (tank) =>
                            tank?.quantity > 0 &&
                            !srcTankFieldArray?.fields?.some(
                              (field) => field.tankData.value == tank?.id
                            )
                        )
                        ?.map((tank) => {
                          return {
                            ...tank,
                            value: tank?.id,
                            label: tank.name,
                            name:
                              tank?.name +
                              ` (${
                                lots?.find((lot) => lot?.id === tank?.batch_id)
                                  ?.name || getLabel("tankState_EMPTY")
                              })`,
                            quantity: tank.quantity,
                            capacity: tank.capacity,
                            color: tank.color,
                            lot: tank.batch_name,
                            lot_id: tank?.batch_id || "",
                          };
                        })}
                      tooltip={
                        idx === 0 ? getLabel("analysisSourceTankTooltip") : ""
                      }
                      onTableChange={() => checkState(idx)}
                    />
                    {getValues(`src_tanks.${idx}.tankData`)?.value && (
                      <TankIcon
                        tank={getValues(`src_tanks.${idx}.tankData`)}
                        isText={true}
                        total={null}
                        isSrc={false}
                      />
                    )}
                    {srcTankFieldArray?.fields?.length > 1 && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          srcTankFieldArray?.remove(idx);
                        }}
                      >
                        -
                      </button>
                    )}
                  </div>
                );
              })}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  srcTankFieldArray?.append({
                    tankData: {},
                  });
                }}
              >
                +
              </button>
            </div>
          </>
        )}
        <h4>{getLabel("operationAdditionalConfiguration")}</h4>
        <div className="operation_form_wrapper_row">
          <MultipleSelect
            name={`cellarman_ids`}
            control={control}
            label={getLabel("operationUser")}
            placeholder={getLabel("operationUserPlaceholder")}
            error={errors["cellarman_ids"]}
            required={getLabel("inputRequiredError")}
            options={users?.map?.((user) => {
              return {
                value: user?.id,
                label: user?.username,
              };
            })}
            tooltip={getLabel("destemmingCellarmenTooltip")}
          />
          <Select
            name="priority"
            control={control}
            label={getLabel("operationPriority")}
            placeholder={getLabel("operationPriorityPlaceholder")}
            error={errors}
            required={getLabel("inputRequiredError")}
            options={[
              {
                label: getLabel("lotQuality_HIGH"),
                value: "2",
              },
              {
                label: getLabel("lotQuality_MEDIUM"),
                value: "1",
              },
              {
                label: getLabel("lotQuality_LOW"),
                value: "0",
              },
            ]}
            // defaultValue={{
            //     value: "",
            //     label: "",
            //   }}
          />
        </div>
        <div className="operation_form_wrapper_row">
          <Select
            label={getLabel("protocolFrequency")}
            placeholder={getLabel("protocolFrequencyPlaceholder")}
            error={errors}
            name="protocolFrequency"
            control={control}
            required={getLabel("inputRequiredError")}
            options={[
              {
                label: getLabel("protocolSpot"),
                value: "spot",
              },
              {
                label: getLabel("protocolRepeat"),
                value: "repeat",
              },
            ]}
          />
          {getValues("protocolFrequency")?.value === "spot" && (
            <>
              <DatePicker
                name="expire_date"
                control={control}
                label={getLabel("operationExpireDateForm")}
                error={errors["expire_date"]}
                required={getLabel("inputRequiredError")}
                dateFormat={configs.shortDateFormat}
                placeholder={getLabel("expiringDatePlaceholder")}
              />
              <Input
                name={"expire_date_exact"}
                register={register}
                label={getLabel("isExactDate")}
                error={errors["expire_date_exact"]}
                // isLabel={false}
                type={"checkbox"}
                checked={isExactDate}
                onChange={() => setIsExactDate((prev) => !prev)}
                customClassName={"confirm"}
                tooltip={getLabel("destemmingExactDate")}
              />
            </>
          )}
          {getValues("protocolFrequency")?.value === "repeat" && (
            <>
              <DatePicker
                name="repeat_start_date"
                control={control}
                label={getLabel("operationStartDateForm")}
                error={errors["repeat_start_date"]}
                required={getLabel("inputRequiredError")}
                dateFormat={configs.shortDateFormat}
                placeholder={getLabel("expiringDatePlaceholder")}
                defaultValue={new Date()}
              />
              <Input
                name={"repeatEvery"}
                register={register}
                type="number"
                error={errors["repeatEvery"]}
                label={getLabel(`repeatEvery`)}
                placeholder={getLabel("repeatEveryPlaceholder")}
                tooltip={getLabel("repeatEveryTooltip")}
                required={getLabel("inputRequiredError")}
                max={{
                  value: 100,
                  message: getLabel("errorMaxValue", { value: 100 }),
                }}
                min={{
                  value: 1,
                  message: getLabel("errorMinValue", { value: 1 }),
                }}
                regExpPattern={{
                  value: INT_REGEX,
                  message: getLabel("errorInt"),
                }}
              />
              <Select
                name="repeatSize"
                control={control}
                label={getLabel("repeatSize")}
                placeholder={getLabel("repeatSizePlaceholder")}
                error={errors["repeatSize"]}
                required={getLabel("inputRequiredError")}
                options={[
                  {
                    label: getLabel("repeatSize_DAILY"),
                    value: "DAILY",
                  },
                  // {
                  //   label: getLabel("repeatSize_HOUR"),
                  //   value: "HOUR",
                  // },
                  {
                    label: getLabel("repeatSize_DAY"),
                    value: "DAY",
                  },
                  {
                    label: getLabel("repeatSize_WEEK"),
                    value: "WEEK",
                  },
                  // {
                  //   label: getLabel("repeatSize_MONTH"),
                  //   value: "MONTH",
                  // },
                ]}
                // tooltip={getLabel("repeatSizeTooltip")}
                tooltip={getLabel("repeatSizeDailyTooltip")}
              />
              {getValues("repeatSize")?.value === "WEEK" && (
                <MultipleSelect
                  name={`repeatDay`}
                  control={control}
                  label={getLabel("repeatDay")}
                  placeholder={getLabel("repeatDayPlaceholder")}
                  error={errors["repeatDay"]}
                  required={getLabel("inputRequiredError")}
                  options={[
                    {
                      label: getLabel("repeatDay_MONDAY"),
                      value: "MONDAY",
                    },
                    {
                      label: getLabel("repeatDay_TUESDAY"),
                      value: "TUESDAY",
                    },
                    {
                      label: getLabel("repeatDay_WEDNESDAY"),
                      value: "WEDNESDAY",
                    },
                    {
                      label: getLabel("repeatDay_THURSDAY"),
                      value: "THURSDAY",
                    },
                    {
                      label: getLabel("repeatDay_FRIDAY"),
                      value: "FRIDAY",
                    },
                    {
                      label: getLabel("repeatDay_SATURDAY"),
                      value: "SATURDAY",
                    },
                    {
                      label: getLabel("repeatDay_SUNDAY"),
                      value: "SUNDAY",
                    },
                  ]}
                  // tooltip={getLabel("destemmingCellarmenTooltip")}
                />
              )}
              <Select
                name="repeatEnd"
                control={control}
                label={getLabel("repeatEnd")}
                placeholder={getLabel("repeatEndPlaceholder")}
                error={errors["repeatEnd"]}
                required={getLabel("inputRequiredError")}
                options={[
                  {
                    label: getLabel("repeatEnd_DATE"),
                    value: "DATE",
                  },
                  {
                    label: getLabel("repeatEnd_OCCURRENCES"),
                    value: "OCCURRENCES",
                  },
                ]}
              />
              {getValues("repeatEnd")?.value === "DATE" && (
                <DatePicker
                  name="repeat_end_date"
                  control={control}
                  label={getLabel("operationEndDateForm")}
                  error={errors["repeat_end_date"]}
                  required={getLabel("inputRequiredError")}
                  dateFormat={configs.shortDateFormat}
                  placeholder={getLabel("expiringDatePlaceholder")}
                />
              )}
              {getValues("repeatEnd")?.value === "OCCURRENCES" && (
                <Input
                  name={"repeatOccurrences"}
                  error={errors["repeatOccurrences"]}
                  register={register}
                  type="number"
                  label={getLabel(`repeatOccurrences`)}
                  placeholder={getLabel("repeatOccurrencesPlaceholder")}
                  required={getLabel("inputRequiredError")}
                  max={{
                    value: 10,
                    message: getLabel("errorMaxValue", { value: 10 }),
                  }}
                  min={{
                    value: 1,
                    message: getLabel("errorMinValue", { value: 1 }),
                  }}
                  regExpPattern={{
                    value: INT_REGEX,
                    message: getLabel("errorInt"),
                  }}
                />
              )}
            </>
          )}
        </div>
        <div className="client_form_wrapper_row">
          <TextArea
            name="note"
            register={register}
            label={getLabel("clientNotes")}
            placeholder={getLabel("clientNotesPlaceholder")}
            error={errors["note"]}
          />
        </div>
        <div className="operation_form_wrapper_row">
          <Submit label={getLabel("submitForm")}></Submit>
        </div>
      </form>
      <ConfirmationModal
        isOpen={isOpen}
        onConfirm={submitForm}
        onClose={handleCloseModal}
        description={getLabel("modalNewRepetitiveAnalysis")}
        isOperationRecap={true}
        totalStep={0}
        errors={errors}
        getValues={getValues}
        // configs={configs}
      >
        <OperationRecap
          type={"RECURRENT_ANALYSIS"}
          control={control}
          register={register}
          getValues={getValues}
          errors={errors}
          configs={configs}
          recursiveDates={recursiveDates}
          setRecursiveDates={setRecursiveDates}
        />
      </ConfirmationModal>
    </div>
  );
};
export default AnalysisForm;
