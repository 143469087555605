import React, { useEffect } from "react";

import { ROLES } from "./constants/base";

// import Register from "./components/Register";
import Login from "./components/Login";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import Unauthorized from "./components/Unauthorized";
import PersistentLogin from "./components/PersistentLogin";

import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import useLabels from "./hooks/useLabels";
import { useDispatch, useSelector } from "react-redux";
import { setLocale } from "./context/configs/configsSlice";
import Tanks from "./components/Tanks";
import TankDetail from "./components/TankDetail";
import OperationsHub from "./components/OperationsHub";
import Lots from "./components/Lots";
import LotDetail from "./components/LotDetail";
import OperationsForm from "./components/OperationsForm";
import OperationsDetail from "./components/OperationsDetail";
import Protocols from "./components/Protocols";
import ProtocolDetail from "./components/ProtocolDetail";
import WineMakingProtocols from "./components/WineMakingProtocols";
import Clients from "./components/Clients";

import "react-datepicker/dist/react-datepicker.css";
import { useMedia } from "react-use";
import "./assets/fontawesome";
import WineMakingProtocolDetail from "./components/WineMakingProtocolDetail";
import Tracking from "./components/Tracking/Tracking";
import { selectConfigs } from "./context/selectors";
import UserCustomzation from "./components/UserCustomization";
import { getItem } from "./services/LocalStorage";
import { socket } from "./socket";
import AdditionalInfo from "./components/AdditionalInfo";
import CookieBar from "./components/CookieBar";
import Loader from "./components/Loader";

import loadable from "@loadable/component";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import { Fallback } from "./components/ErrorBoundary/Fallback";

const LazyOperationsHistory = loadable(() => import("./components/OperationsHistory"), { fallback: <Loader/> });
const LazySubstanceForm = loadable(() => import("./components/SubstanceForm"), { fallback: <Loader/> });
const LazyWineMakingProtocolForm = loadable(() => import("./components/WineMakingProtocolForm"), { fallback: <Loader/> });
const LazyProtocolForm = loadable(() => import("./components/ProtocolForm"), { fallback: <Loader/> });
const LazyWineries = loadable(() => import("./components/Wineries"), { fallback: <Loader/> });
const LazyWineryForm = loadable(() => import("./components/WineryForm"), { fallback: <Loader/> });
const LazyWineryDetail = loadable(() => import("./components/WineryDetail"), { fallback: <Loader/> });
const LazyUserForm = loadable(() => import("./components/UserForm"), { fallback: <Loader/> });
const LazyUserDetail = loadable(() => import("./components/UserDetail"), { fallback: <Loader/> });
const LazyClientForm = loadable(() => import("./components/ClientForm"), { fallback: <Loader/> });
const LazyClientDetail = loadable(() => import("./components/ClientDetail"), { fallback: <Loader/> });
const LazyTankForm = loadable(() => import("./components/TankForm"), { fallback: <Loader/> });
const LazyLotForm = loadable(() => import("./components/LotForm"), { fallback: <Loader/> });
const LazySubstanceDetail = loadable(() => import("./components/SubstanceDetail"), { fallback: <Loader/> });

function App() {
  const isMobile = useMedia("(max-width: 1024px)");
  const { area, locale } = useSelector(selectConfigs);

  const [_getLabel, _getArea, setLabels] = useLabels();
  const dispatch = useDispatch();

  // const [touchStart, setTouchStart] = useState(null)
  // const [touchEnd, setTouchEnd] = useState(null)

  // // the required distance between touchStart and touchEnd to be detected as a swipe
  // const minSwipeDistance = 50

  // const onTouchStart = (e) => {
  //   setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
  //   setTouchStart(e.targetTouches[0].clientX)
  // }

  // const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  // const onTouchEnd = () => {
  //   if (!touchStart || !touchEnd) return
  //   const distance = touchStart - touchEnd
  //   const isLeftSwipe = distance > minSwipeDistance
  //   const isRightSwipe = distance < -minSwipeDistance
  //   if (isRightSwipe)  setIsOpen(true);
  //   if (isLeftSwipe)  setIsOpen(false);

  //   if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
  //   // add your conditional logic here
  // }

  useEffect(() => {
    const localStorageLanguage = getItem("language") || "it_IT";
    dispatch(
      setLocale({ locale: localStorageLanguage?.split("_")?.[0] || "it" })
    );
    setLabels();
  }, []);

  useEffect(() => {
    setLabels();
  }, [area, locale]);

  return (
    <ErrorBoundary fallbackRender={Fallback}>
      <Routes>
        <Route path="/">
          {/* public routes */}
          <Route element={<Layout />}>
            <Route path="/login" exact element={<Login />} />
            {/* <Route path="/register" exact element={<Register />} /> */}
            <Route path="/unauthorized" exact element={<Unauthorized />} />
            <Route
              path="/privacy"
              exact
              element={<AdditionalInfo type="privacy" />}
            />
            <Route
              path="/cookie"
              exact
              element={<AdditionalInfo type="cookie" />}
            />
            <Route
              path="/set-cookie"
              exact
              element={<CookieBar isBar={false} />}
            />
          </Route>
          <Route
            element={<PersistentLogin isMobile={isMobile} socket={socket} />}
          >
            {/* admin routes [ 1 ] */}
            <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
              <Route path="/admin" element={<Home />} />
              <Route path="/clients" exact element={<Clients />} />
              <Route path="/clients/new" exact element={<LazyClientForm />} />
              <Route path="/clients/:id" exact element={<LazyClientDetail />} />
              <Route path="/clients/:id/update" exact element={<LazyClientForm />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.CLIENT]} />}>
              {/* <Route path="/" element={<Home isMobile={isMobile} />} /> */}
              {/* <Route path="/programming" exact element={<OperationsHub isMobile={isMobile} />} /> */}
              <Route
                path="/programming/history"
                exact
                element={<LazyOperationsHistory />}
              />
              <Route
                path="/programming/:type/new"
                exact
                element={<OperationsForm isMobile={isMobile} />}
              />
              <Route
                path="/programming/:type/:id/duplicate"
                exact
                element={<OperationsForm isDuplicate={true} isMobile={isMobile} />}
              />
              {/* <Route path="/programming/:type/:id" exact element={<OperationsDetail isMobile={isMobile} />} /> */}
              <Route
                path="/programming/:type/:id/update"
                exact
                element={<OperationsForm isMobile={isMobile} />}
              />
              {/* <Route path="/lots" exact element={<Lots />} /> */}
              <Route path="/lots/new" exact element={<LazyLotForm />} />
              {/* <Route path="/lots/:id" exact element={<LotDetail/>} /> */}
              <Route path="/lots/:id/update" exact element={<LazyLotForm />} />
              {/* <Route path="/tanks" exact element={<Tanks />} /> */}
              <Route path="/tanks/new" exact element={<LazyTankForm />} />
              {/* <Route path="/tanks/:id" exact element={<TankDetail/>} /> */}
              <Route path="/tanks/:id/update" exact element={<LazyTankForm />} />
              {/* <Route path="/substances/:type/:id" exact element={<SubstanceDetail/>}/> */}
              <Route
                path="/substances/:type/new"
                exact
                element={<LazySubstanceForm />}
              />
              <Route
                path="/substances/:type/:id/update"
                exact
                element={<LazySubstanceForm />}
              />
              {/* <Route path="/protocols/:protocolType" exact element={<Protocols/>} /> */}
              {/* <Route path="/protocols/treatment" exact element={<Protocols protocolType={'treatment'}/>} /> */}
              {/* <Route path="/protocols/analysis" exact element={<Protocols protocolType={'analysis'}/>} /> */}
              {/* <Route path="/protocols/:type/:id" exact element={<ProtocolDetail/>} /> */}
              <Route
                path="/protocols/winemaking/:type/new"
                exact
                element={<LazyWineMakingProtocolForm />}
              />
              <Route
                path="/protocols/winemaking/:type/:id/update"
                exact
                element={<LazyWineMakingProtocolForm />}
              />
              <Route
                path="/protocols/:type/new"
                exact
                element={<LazyProtocolForm />}
              />
              <Route
                path="/protocols/:type/:id/update"
                exact
                element={<LazyProtocolForm />}
              />
              {/* <Route path="/wineries" exact element={<Wineries />} /> */}
              {/* <Route path="/wineries/:id" exact element={<WineryDetail/>} /> */}
              <Route path="/wineries/new" exact element={<LazyWineryForm />} />
              <Route path="/wineries/:id/update" exact element={<LazyWineryForm />} />
              {/* <Route path="/users/:id" exact element={<UserDetail />} /> */}
              <Route path="/users/new" exact element={<LazyUserForm />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[ROLES.CLIENT, ROLES.USER, ROLES.TANK]}
                />
              }
            >
              <Route path="/" element={<Home isMobile={isMobile} />} />
              <Route path="/programming" exact element={<OperationsHub isMobile={isMobile} />} />
              <Route
                path="/programming/history"
                exact
                element={<OperationsHub isMobile={isMobile} />}
              />
              <Route
                path="/programming/:type/:id"
                exact
                element={<OperationsDetail isMobile={isMobile} />}
              />
              <Route path="/lots" exact element={<Lots isMobile={isMobile} />} />
              <Route path="/lots/:id" exact element={<LotDetail />} />
              <Route
                path="/lots/:id/tracking"
                exact
                element={<Tracking type={"lot"} />}
              />
              <Route
                path="/tanks"
                exact
                element={<Tanks isMobile={isMobile} />}
              />
              <Route path="/tanks/:id" exact element={<TankDetail />} />
              <Route
                path="/tanks/:id/tracking"
                exact
                element={<Tracking type={"tank"} />}
              />
              <Route
                path="/substances/:type/:id"
                exact
                element={<LazySubstanceDetail />}
              />
              <Route
                path="/protocols/:protocolType"
                exact
                element={<Protocols isMobile={isMobile} />}
              />
              <Route
                path="/protocols/winemaking/:type"
                exact
                element={<WineMakingProtocols isMobile={isMobile} />}
              />
              <Route
                path="/protocols/winemaking/:type/:id"
                exact
                element={<WineMakingProtocolDetail />}
              />
              <Route
                path="/protocols/:type/:id"
                exact
                element={<ProtocolDetail />}
              />
              <Route path="/wineries" exact element={<LazyWineries />} />
              <Route path="/wineries/:id" exact element={<LazyWineryDetail />} />
              <Route path="/users/:id" exact element={<LazyUserDetail />} />
              <Route path="/users/:id/update" exact element={<LazyUserForm />} />
              <Route
                path="/users/:id/customize"
                exact
                element={<UserCustomzation />}
              />
            </Route>

            {/* admin and client routes [ 1, 2 ]*/}
            {/* <Route
              element={<RequireAuth allowedRoles={[ROLES.ADMIN, ROLES.CLIENT]} />}
            >
            </Route> */}
          </Route>
        </Route>
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
