import { useNavigate } from "react-router-dom";
import winery_img from "../../assets/icons/winery.png";

const WineryTile = ({ isMobile, name, city, id, clickHandler = () => null }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (clickHandler) clickHandler();
    // if (isMobile) navigate(`/wineries/${id}`);
  };

  return (
    <div
      className={`winery_tile ${true ? "is_winery_tile" : ""}`}
      key={`winery-tile-${id}`}
      onClick={handleClick}
    >
      <div className="winery_tile_content">
          <div className="winery_tile_content_name">{name}</div>
        <div className="winery_tile_content_img_container">
          <img src={winery_img} alt="winery" />
        </div>
        <div className="winery_tile_content_code">{city}</div>
      </div>
    </div>
  );
};

export default WineryTile;
