import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import useLabels from "../../../hooks/useLabels";
import { resetActive } from "../../../context/wineries/wineriesSlice";
import { CTA } from "../../Generic";
import "./styles/index.scss";
import "../../../components/Header/styles/index.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { empty } from "../../../context/operations/operationsSlice";
import { emptyLots } from "../../../context/lots/lotsSlice";
import { emptyTanks } from "../../../context/tanks/tanksSlice";
import { emptyAnalysis } from "../../../context/protocols/analysisSlice";
import { selectUser, selectUsers, selectWineries } from "../../../context/selectors";
import { emptyExams } from "../../../context/substances/examsSlice";
import { emptyUsers } from "../../../context/users/usersSlice";
import { emptyExp } from "../../../context/expOperations/expOperationsSlice";

const variants = {
  container: {
    open: {
      y: 0,
      opacity: 1,
    },
    closed: {
      y: -10,
      opacity: 0,
    },
  },
  // used to stagger item animation when switching from closed to open and vice versa
  content: {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  },
  item: {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  },
};

const Settings = ({ isOpen, setSettingsOpen }) => {
  const [getLabel] = useLabels();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const users = useSelector(selectUsers);
  const user_id = (users?.find((u) => u?.username === user?.userName))?.id || '';
  
  return (
    <>
      {isOpen && (
        <motion.aside onClick={e => e.stopPropagation()}
          className="motion-aside-container"
          initial={false}
          variants={variants.container}
          animate={isOpen ? "open" : "closed"}
        >
          <header className="header">
            <h3>{getLabel("settings")}</h3>
          </header>
          <AnimatePresence>
            <motion.section
              className="motion-section-content"
              variants={variants.content}
              animate={isOpen ? "open" : "closed"}
            >
              <AnimatePresence>
              {/* {wineries?.length > 1 && user?.type !== 'TANK' && <CTA isFull={true} onClick={changeWinery}>{getLabel("settingsChangeWinery")}</CTA>} */}
              <NavLink to={user_id ? `/users/${user_id}/customize` : ''} onClick={setSettingsOpen}>
                <CTA isFull={true} >{getLabel("settingsPersonalInfo")}</CTA>
              </NavLink>
              {/* {user?.role === 2 && <CTA isFull={true} >{getLabel("settingsExportDB")}</CTA>} */}
              </AnimatePresence>
            </motion.section>
          </AnimatePresence>
          <footer className="footer">
          </footer>
        </motion.aside>
      )}{" "}
    </>
  );
};

export default Settings;
