import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icons, toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";
import { selectNotificationsUnread } from "../../../context/selectors";
import useLabels from "../../../hooks/useLabels";
import { Switch } from "../../FormComponents";
import { toggleNotificationsUnreadGrid } from "../../../context/configs/configsSlice";
import { Button } from "../../Generic";
import "./styles/index.scss";
import "../../../components/Header/styles/index.scss";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { ROLES } from "../../../constants/base";
import useAuth from "../../../hooks/useAuth";
import { getOperations } from "../../../services/utils";

const variants = {
  container: {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
    closed: {
      opacity: 0,
      x: '-100%',
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
  },
  content: {
    open: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        delay: 0.2, // add a delay to stagger the animation
        ease: 'easeInOut',
      },
    },
    closed: {
      opacity: 0,
      y: '-100%',
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
  },
};

const NotificationsCenter = ({ isOpen, setCounter }) => {
  const [getLabel] = useLabels();
  const dispatch = useDispatch();
  const showUnreadOnly = useSelector(selectNotificationsUnread);
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;

  const {
    notifications,
    clear,
    markAllAsRead,
    markAsRead,
    remove,
    unreadCount,
  } = useNotificationCenter({
    filter: (item) => item.data.exclude === false,
  });

  const sameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  const isDailyReport = async () => {
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0);
    const completedOps =
      (await getOperations(axiosPrivate, true, null, startTime)) || [];
    if (completedOps?.length > 0) {
      const customIdDailyReport = "custom-id-daily-report";
      // console.log(getLabel('toast_dailyReport'))
      // TO DO label toast_dailyReport non tradotta perché componente caricato troppo presto, prima di setLabels('private')
      toast(getLabel("toast_dailyReport"), {
        toastId: customIdDailyReport,
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        icon: Icons.success({
          theme: "light",
          type: toast.TYPE.SUCCESS,
        }),
        position: toast.POSITION.BOTTOM_RIGHT,
        data: {
          exclude: false,
        },
      });
    }
  };

  useEffect(() => {
    setCounter(unreadCount);
  }, [unreadCount, notifications]);

  useEffect(() => {
    const endTime = new Date();
    endTime.setHours(19, 54, 30, 0);
    // endTime.setHours(20, new Date().getMinutes(), new Date().getSeconds() + 5, 0);
    const timeout = endTime - new Date();

    if (role === ROLES.CLIENT && timeout > 0 && sameDay(endTime, new Date()))
      setTimeout(isDailyReport, timeout);
  }, []);

  const toggleDisplayMode = () => {
    dispatch(toggleNotificationsUnreadGrid());
  };
  
  return (
    <>
      {isOpen && (
        <motion.aside
          onClick={(e) => e.stopPropagation()}
          className="motion-aside-container"
          initial={false}
          variants={variants.container}
          animate={isOpen ? 'open' : 'closed'}
        >
          <header className="header">
            <h3>{getLabel("notifications")}</h3>
            <div className="unread-filter">
              <Switch
                negative={true}
                leftOption={{
                  label: showUnreadOnly
                    ? getLabel("notificationsShowUnread")
                    : getLabel("notificationsShowAll"),
                }}
                rightOption={{
                  label: "",
                }}
                toggleHandler={toggleDisplayMode}
                status={showUnreadOnly}
              />
              {/* <Switch
              id="unread-filter"
              checked={showUnreadOnly}
              onChange={() => {
                toggleFilter(!showUnreadOnly);
              }}
            /> */}
            </div>
          </header>
          <AnimatePresence>
            <motion.section
              className="motion-section-content"
              variants={variants.content}
              animate={isOpen ? "open" : "closed"}
            >
              {(!notifications.length ||
                (unreadCount === 0 && showUnreadOnly)) && (
                <motion.h4
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {getLabel("notificationsNoItems")}
                </motion.h4>
              )}
              <AnimatePresence>
                {(showUnreadOnly
                  ? notifications.filter((v) => !v.read)
                  : notifications
                ).map((notification) => {
                  return (
                    <motion.div
                      key={notification.id}
                      layout
                      initial={{ scale: 0.4, opacity: 0, y: 50 }}
                      exit={{
                        scale: 0,
                        opacity: 0,
                        transition: { duration: 0.2 },
                      }}
                      animate={{ scale: 1, opacity: 1, y: 0 }}
                      style={{ padding: "0.8rem" }}
                    >
                      <motion.article
                        className="motion-article-item"
                        key={notification.id}
                        variants={variants.item}
                      >
                        <div className="icon-wrapper">
                          {notification.icon ||
                            Icons.info({
                              theme: notification.theme || "light",
                              type: toast.TYPE.INFO,
                            })}
                        </div>
                        <div>
                          <div>{notification.content}</div>
                          {/* <TimeTracker createdAt={notification.createdAt} /> */}
                        </div>
                        {/* <ItemActions
                        notification={notification}
                        markAsRead={markAsRead}
                        remove={remove}
                      /> */}
                      </motion.article>
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </motion.section>
          </AnimatePresence>
          <footer className="footer">
            <Button
              isRed={true}
              isFull={true}
              label={getLabel("notificationsClearAll")}
              customClassName="sidebar_container_action"
              isNavLink={true}
              disableIcon={true}
              onClick={clear}
            />
            <Button
              isRed={true}
              isFull={true}
              label={getLabel("notificationsMarkAll")}
              customClassName="sidebar_container_action"
              isNavLink={true}
              disableIcon={true}
              onClick={markAllAsRead}
            />
            {/* <button onClick={clear}>{getLabel("notificationsClearAll")}</button>
            <button onClick={markAllAsRead}>
              {getLabel("notificationsMarkAll")}
            </button> */}
          </footer>
        </motion.aside>
      )}{" "}
    </>
  );
};

export default NotificationsCenter;
