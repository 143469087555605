import useLabels from "../../hooks/useLabels";
import humidity_sensor from "../../assets/icons/humidity-sensor.png";
import temperature_sensor from "../../assets/icons/temperature-sensor.png";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectConfigs } from "../../context/selectors";
import "./styles/index.scss";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfirmationModal, OperationRecap, Tooltip } from "../Generic";
import { useForm, useWatch } from "react-hook-form";
import useAuth from "../../hooks/useAuth";
import { ROLES } from "../../constants/base";
import { toast } from "react-toastify";
import {
  setTankSensorData,
  setTankSensorEnable,
  setTankSensorWorkMode,
} from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { emptyTanks } from "../../context/tanks/tanksSlice";
import { Select, Switch } from "../FormComponents";

const Sensor = ({ data, tank_id, showPlotData = () => null }) => {
  const [getLabel] = useLabels();
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const dispatch = useDispatch();
  const role = auth?.role || ROLES.GUEST;
  const configs = useSelector(selectConfigs);
  const [isShowInPlot, setIsShowInPlot] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(null);
  const [statusClass, setStatusClass] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    setValue,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      new_set_point: "",
      select: 0,
    },
    mode: "onTouched",
  });

  const [enable, setEnable] = useState(false);
  const { work_mode } = useWatch({ control });
  const prevValuesRef = useRef({ enable, work_mode });
  const [userTriggeredChange, setUserTriggeredChange] = useState(false);

  useEffect(() => {
    if (isNaN(data?.work_mode)) {
      setValue('work_mode', undefined);
    } else {
      setValue('work_mode', { value: Number(data?.work_mode), label: getLabel(`sensor_TEMPERATURE_${data?.work_mode}`)});
      prevValuesRef.current.work_mode = data?.work_mode;

    }

    if (data?.state === true || data?.state === false) {
      setEnable(data?.state);
      prevValuesRef.current.enable = data?.state;
    }
  }, [data]);

  useEffect(() => {
    const changeEnable = async () => {
      try {
        setIsLoading(true);
        const isEnableChanged = prevValuesRef.current?.enable !== enable;

        if (isEnableChanged) await sendEnable();
      } catch (error) {
        console.error("Error changing the enable:", error);
        // Reset previous values
        setEnable(prevValuesRef.current.enable);
      } finally {
        setIsLoading(false);
      }
    };

    // only call changeEnable if the change was user-triggered
    if (!isLoading && userTriggeredChange) {
      changeEnable();
      setUserTriggeredChange(false); // Reset the flag
    }

  }, [enable]);

  useEffect(() => {
    const changeWorkMode = async () => {
      try {
        setIsLoading(true);
        const work_mode_value = work_mode?.value;
        const isWorkModeChanged = prevValuesRef.current?.work_mode?.value !== work_mode_value;

        if (isWorkModeChanged && Number(work_mode_value) >= 0) {
          await sendWorkMode();
        }
      } catch (error) {
        console.error("Error changing the work mode:", error);
        // Reset previous values
        setValue('work_mode', prevValuesRef.current.work_mode);
      } finally {
        setIsLoading(false);
      }
    };

    // only call changeWorkMode if the change was user-triggered
    if (!isLoading && userTriggeredChange) {
      changeWorkMode();
      setUserTriggeredChange(false); // Reset the flag
    }

  }, [work_mode]);

  useEffect(() => {
    if (apiSuccess !== null) {
      // determine class based on API result
      const className = apiSuccess ? 'success' : 'error';
      setStatusClass(className);
      
      // reset class after 1.5 second
      const timer = setTimeout(() => {
        setStatusClass('');
        setApiSuccess(null);
      }, 1500);

      // clean up the timer
      return () => clearTimeout(timer);
    }
  }, [apiSuccess]);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleFormSubmit = async () => {
    await setNewSetPoint();
    handleCloseModal();
  };

  const enablePlot = () => {
    if (!data?.last_point?.value) return;

    const isEnabled = !isShowInPlot;
    setIsShowInPlot(isEnabled);
    showPlotData(data?.type, !isEnabled);
  };

  const toggleEnable = () => {
    setEnable((prev) => {
      setUserTriggeredChange(true); // Set the flag when the user toggles
      // if (!prev) setValue('work_mode', undefined)
      return !prev;
    });
  };

  const handleWorkModeChange = (opt) => {
    setValue("work_mode", opt);
    setUserTriggeredChange(true);
  };

  const getIcon = () => {
    switch (data?.type) {
      case "TEMPERATURE":
        return temperature_sensor;
      case "HUMIDITY":
        return humidity_sensor;
      default:
        return null;
    }
  };

  const sendEnable = async () => {
    const values = getValues();
    const username = values?.username;
    const password = values?.password;
    const auth = role === ROLES.TANK ? { username, password } : {};

    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });

    const response = await setTankSensorEnable(
      data?.type,
      tank_id,
      enable,
      auth,
      axiosPrivate
    );
    console.log("response", response);
    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { name: data?.type || "" })
        : getLabel(response?.error),
      type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });
    if (response && response?.success) {
      dispatch(emptyTanks());
      // update previous values only on success
      prevValuesRef.current.enable = enable;
      // prevValuesRef.current = { enable: enable, work_mode: { value: work_mode, label: getLabel(`sensor_TEMPERATURE_${work_mode}`)} };
      setApiSuccess(true);
    } else {
      setApiSuccess(false);
      throw new Error();
    }
  };

  const sendWorkMode = async () => {
    const values = getValues();
    const work_mode = values?.work_mode?.value;
    const username = values?.username;
    const password = values?.password;
    const auth = role === ROLES.TANK ? { username, password } : {};

    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });

    const response = await setTankSensorWorkMode(
      data?.type,
      tank_id,
      work_mode,
      auth,
      axiosPrivate
    );
    console.log("response", response);
    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { name: data?.type || "" })
        : getLabel(response?.error),
      type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });
    if (response && response?.success) {
      dispatch(emptyTanks());
      // update previous values only on success
      prevValuesRef.current.work_mode = { value: work_mode, label: getLabel(`sensor_TEMPERATURE_${work_mode}`)};
      // prevValuesRef.current = { enable: enable, work_mode: { value: work_mode, label: getLabel(`sensor_TEMPERATURE_${work_mode}`)} };
      setApiSuccess(true);
    } else {
      setApiSuccess(false);
      throw new Error();
    }
  };

  const customNewSetPointErrorToastId = "custom-new-set-point-error";

  const setNewSetPoint = async () => {
    const values = getValues();
    const newSetPoint = values?.new_set_point;
    const username = values?.username;
    const password = values?.password;
    const auth = role === ROLES.TANK ? { username, password } : {};
    if (Number(data?.set_point) === Number(newSetPoint)) return;

    if (!newSetPoint) {
      toast.error(
        getLabel("toast_newSetPointError", {
          name: data?.translated_name || "",
        }),
        {
          toastId: customNewSetPointErrorToastId,
          type: toast.TYPE.ERROR,
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 4000,
        }
      );
      return;
    } else {
      toast.dismiss(customNewSetPointErrorToastId);
    }

    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });

    const response = await setTankSensorData(
      data?.type,
      tank_id,
      newSetPoint,
      auth,
      axiosPrivate
    );
    console.log("response", response);
    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { name: data?.type || "" })
        : getLabel(response?.error),
      type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });
    if (response && response?.success) {
      dispatch(emptyTanks());
      setApiSuccess(true);
    } else {
      setApiSuccess(false);
    }
  };

  if (!data) return;

  const renderEnableByType = () => {
    switch (data?.type) {
      case "TEMPERATURE":
        return (
          <div className="enable">
            {[ROLES.CLIENT, ROLES.TANK].includes(role) && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Switch
                  disabled={isLoading}
                  leftOption={{
                    label: getLabel("sensor_TEMPERATURE_enable_OFF"),
                  }}
                  rightOption={{
                    label: getLabel("sensor_TEMPERATURE_enable_ON"),
                  }}
                  toggleHandler={toggleEnable}
                  status={enable}
                />
              </div>
            )}
          </div>
        );
      default:
        return;
    }
  };

  const getSelectOptionsByType = () => {
    switch (data?.type) {
      case "TEMPERATURE":
        return [
          { value: 0, label: getLabel("sensor_TEMPERATURE_0") },
          { value: 1, label: getLabel("sensor_TEMPERATURE_1") },
          { value: 2, label: getLabel("sensor_TEMPERATURE_2") },
          { value: 3, label: getLabel("sensor_TEMPERATURE_3") },
        ];
      default:
        return [{ value: "", label: "" }];
    }
  };

  return (
    <>
      {[ROLES.CLIENT, ROLES.TANK].includes(role) && (
        <ConfirmationModal
          isOpen={isOpen}
          onConfirm={handleFormSubmit}
          onClose={handleCloseModal}
          description={getLabel("modalNewSetPointDescription", {
            name: getLabel(`sensor_${data?.type}`) || "",
          })}
        >
          <OperationRecap
            type={"SENSOR_SETPOINT"}
            control={control}
            register={register}
            errors={errors}
            isCredential={role === ROLES.TANK}
            setPointBoundaries={{
              min: data?.min,
              max: data?.max,
              default: data?.set_point,
            }}
          />
        </ConfirmationModal>
      )}
      <div
        className={`sensor ${isShowInPlot ? "selected" : ""} ${
          !data?.last_point?.value ? "no_hover" : ""
        } ${statusClass}`}
        onClick={enablePlot}
      >
        <div className="title_wrapper">
          <h5 className="sensor_title">{data?.translated_name}</h5>
          {(data?.state === true || data?.state === false) && 
          <Tooltip
            variant={"info"}
            html={
              data?.state_by_user && data?.state_by_user_time
                ? getLabel(
                    data?.by_user === -1
                      ? "sensorModifiedSetPointWineryMonitor"
                      : "sensorModifiedSetPoint",
                    { name: data?.state_by_user, date: data?.state_by_user_time }
                  )
                : ""
            }
            place="right"
            events={["hover"]}
          ><div className={`dot ${data?.state ? 'enabled' : 'disabled'}`}></div></Tooltip>}
        </div>
        <div className="sensor_data">
          <div className="column icon_wrapper">
            {getIcon() && (
              <img
                className="icon"
                src={getIcon()}
                alt={data?.value?.toLowerCase()}
              />
            )}
          </div>
          <div className="column column2">
            {!data?.last_point?.value && (
              <div className="current">
                <p className="actual_time">{getLabel("sensorNoData")}</p>
              </div>
            )}
            {data?.last_point?.value && (
              <div className="current">
                <p className="actual_value">
                  {data?.last_point?.value || "-"}{" "}
                  <span className="unit">{data?.unit || ""}</span>
                </p>
                <p className="actual_time">
                  {data?.last_point?.value && data?.last_point?.date
                    ? format(
                        data?.last_point?.date || null,
                        configs.hourMediumDateFormat
                      )
                    : "-"}
                </p>
              </div>
            )}
          </div>
          <div className="column column3">
            {data?.last_point?.value && (
              <div className="set_point_wrapper">
                <Tooltip
                  variant={"info"}
                  html={
                    data?.by_user && data?.by_user_time
                      ? getLabel(
                          data?.by_user === -1
                            ? "sensorModifiedSetPointWineryMonitor"
                            : "sensorModifiedSetPoint",
                          { name: data?.by_user, date: data?.by_user_time }
                        )
                      : ""
                  }
                  place="right"
                  events={["hover"]}
                >
                  <div className="set_point">
                    <p className="set_point_title">
                      {getLabel("sensorSetPoint")}
                    </p>
                    <p className="set_point_value">
                      {data?.set_point || "-"}{" "}
                      <span className="unit">{data?.unit || ""}</span>
                    </p>
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        <div className="sensor_footer">
          <div className="column column1">{renderEnableByType()}</div>
          <div className="column column2" onClick={(e) => e.stopPropagation()}>
              <Select
                name={"work_mode"}
                control={control}
                placeholder={getLabel("setEnableValuePlaceholder")}
                error={errors["work_mode"]}
                required={getLabel("inputRequiredError")}
                options={getSelectOptionsByType()}
                isLabel={false}
                isDisabled={isLoading}
                onTableChange={(e) => handleWorkModeChange(e)}
              />
          </div>
          <div className="column column3">
            <div className="modify_set_point">
              {[ROLES.CLIENT, ROLES.TANK].includes(role) && (
                <button
                  onClick={(e) => {
                    setIsOpen(true);
                    e.stopPropagation();
                  }}
                >
                  {getLabel("sensorModifySetPoint")}{" "}
                  <FontAwesomeIcon icon="fa-gear" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sensor;
