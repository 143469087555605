import { useDispatch, useSelector } from "react-redux";
import { setAll, setArea } from "../context/labels/labelsSlice";
import { selectConfigs, selectLabels } from "../context/selectors";
import jsonLabels from '../assets/labels/labels.json'
import useAxiosPrivate from "./useAxiosPrivate";
import { getLabelByArea } from "../services/utils";

const useLabels = () => {
    const { area, locale } = useSelector(selectConfigs);
    const labels = useSelector(selectLabels);
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();

    const getLabel = (label, replacableTokens = {}) => {
        const storedLabel = getFromStore(label);
        if (storedLabel) {
            if (Object.keys(replacableTokens).length > 0) {
                let rawLabel = storedLabel;
                for (const token in replacableTokens) {
                    rawLabel = rawLabel.replace(`{{${token}}}`, replacableTokens[token] || '')
                }
                return rawLabel
            }
            return storedLabel
        }
        if (Object.keys(replacableTokens).length > 0) {
            let rawLabel = `#${locale}_${area}_${label}`;
            for (const token in replacableTokens) {
                rawLabel = `${rawLabel}:${replacableTokens[token]}`
            }
            return rawLabel
        }
        return `#${locale}_${area}_${label}`
    }

    const getArea = () => {
        const storedArea = getFromStoreArea(area);
        if (storedArea) {
            return storedArea
        }
        return {}
    }

    const getFromStoreArea = (interestedArea) => {
        return labels?.[interestedArea];
    }

    const getFromStore = (label) => {
        return labels?.[locale]?.[area]?.[label]
    }

    const checkSession = () => {
        return Object.keys(labels?.[locale]?.[area] || {}).length > 0;
    }

 
    const isValidDate = (date) => {
        return date instanceof Date && !isNaN(date.getTime());
    }

    const getLabelFromServer = async () => {
        const langLabels = await getLabelByArea(area, axiosPrivate);
        const response = langLabels?.data; 
        const errorLabels = await getLabelByArea('error', axiosPrivate);
        const errorResponse = errorLabels?.data; 

        const errors = {};
        if (errorResponse) {
            for (const [key, dict] of Object.entries(errorResponse)) {
                for (const [id, value] of Object.entries(dict)) {
                    errors[`${key}.${id}`] = value;
                }
            }
        }
        
        let lastModified = null;
        let lastModifiedError = null; 
        if (langLabels.headers && langLabels.headers['last-modified'])
            lastModified = new Date(langLabels.headers?.["last-modified"]);
        if (errorLabels.headers && errorLabels.headers['last-modified'])
            lastModifiedError = new Date(errorLabels.headers?.["last-modified"]);

        const dates = [lastModified, lastModifiedError].filter(isValidDate);
        const maxDate = dates.reduce((a, b) => a > b ? a : b, undefined);
                
        return { ...response, ...errors, ...(maxDate && { lastModifiedTimestamp: maxDate.toISOString() }) } || {};
    }

    const setLabels = async () => {
        if (checkSession()) {
          return;
        }
        const storedLabels = localStorage.getItem(`labels_${area}_${locale}`);
        if (storedLabels) {
          const storedLabelsData = JSON.parse(storedLabels);
          const lastModified = storedLabelsData.lastModified;
          try {
            const serverLabels = await getLabelFromServer();
            if (serverLabels?.lastModifiedTimestamp && serverLabels?.lastModifiedTimestamp > lastModified) {
                const labelsData = { labels: serverLabels, lastModified: serverLabels?.lastModifiedTimestamp };
              localStorage.setItem(`labels_${area}_${locale}`, JSON.stringify(labelsData));
              dispatch(setArea({ locale: locale, area: area, payload: serverLabels }));
            } else {
              dispatch(setArea({ locale: locale, area: area, payload: storedLabelsData.labels }));
            }
          } catch (error) {
            console.error(error);
            dispatch(setArea({ locale: locale, area: area, payload: storedLabelsData.labels }));
          }
        } else {
          try {
            const serverLabels = await getLabelFromServer();
            const labelsData = { labels: serverLabels, lastModified: serverLabels.lastModifiedTimestamp ? serverLabels.lastModifiedTimestamp : new Date().toISOString() };
            localStorage.setItem(`labels_${area}_${locale}`, JSON.stringify(labelsData));
            dispatch(setArea({ locale: locale, area: area, payload: serverLabels }));
          } catch (error) {
            console.error(error);
            // handle the case where there are no labels in localStorage
          }
        }
      };

    return [getLabel, getArea, setLabels]

}


export default useLabels