import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import useLabels from "../../hooks/useLabels";
import { CTA, Tooltip } from "../Generic";
import DebouncedInput from "../TableEdit/DebouncedInput";
import "./styles/index.scss";

const CardFilters = ({
  type,
  data = [],
  appliedFilters = [],
  setAppliedFilters = () => null,
}) => {
  const [getLabel] = useLabels();
  const [filters, setFilters] = useState([]);
  const [filterOpened, setFilterOpened] = useState("");
  const filterRef = useRef();

  useClickOutside(filterRef, () => {
    if (String(filterOpened)?.length > 0) {
      setFilterOpened("");
    }
  });

  useEffect(() => {
    if (!type) return;
    switch (type) {
      case "lot":
        const states = new Set();
        data?.reduce(
          (_, item) =>
            states.add({
              param: "state",
              value: item?.state,
              label: getLabel(`lotState_${item?.state}`),
            }),
          null
        );
        const colors = new Set();
        data?.reduce(
          (_, item) =>
            colors.add({
              param: "color",
              value: item?.color,
              label: getLabel(`lotColor_${item?.color}`),
            }),
          null
        );
        const qualities = new Set();
        data?.reduce(
          (_, item) =>
            qualities.add({
              param: "quality",
              value: item?.quality,
              label: getLabel(`lotQuality_${item?.quality}`),
            }),
          null
        );

        setFilters([
          {
            type: "select",
            value: "state",
            label: getLabel("lotState"),
            data: Object.values(
              Array.from(states).reduce(
                (acc, obj) => ({ ...acc, [obj.value]: obj }),
                {}
              )
            ),
          },
          {
            type: "select",
            value: "color",
            label: getLabel("lotColor"),
            data: Object.values(
              Array.from(colors).reduce(
                (acc, obj) => ({ ...acc, [obj.value]: obj }),
                {}
              )
            ),
          },
          {
            type: "select",
            value: "quality",
            label: getLabel("lotQuality"),
            data: Object.values(
              Array.from(qualities).reduce(
                (acc, obj) => ({ ...acc, [obj.value]: obj }),
                {}
              )
            ),
          },
        ]);
        break;
      case "tank":
        const tank_states = new Set();
        data?.reduce(
          (_, item) => item?.state ? 
          tank_states.add({
              param: "state",
              value: item?.state,
              label: getLabel(`lotState_${item?.state}`),
            }) : null,
          null
        );
        const tank_colors = new Set();
        data?.reduce(
          (_, item) => item?.color ?
          tank_colors.add({
              param: "color",
              value: item?.color,
              label: getLabel(`lotColor_${item?.color}`),
            }) : null,
          null
        );

        setFilters([
          {
            type: "select",
            value: "state",
            label: getLabel("lotState"),
            data: Object.values(
              Array.from(tank_states).reduce(
                (acc, obj) => ({ ...acc, [obj.value]: obj }),
                {}
              )
            ),
          },
          {
            type: "select",
            value: "color",
            label: getLabel("tankColor"),
            data: Object.values(
              Array.from(tank_colors).reduce(
                (acc, obj) => ({ ...acc, [obj.value]: obj }),
                {}
              )
            ),
          },
        ]);
        break;

      default:
        break;
    }
  }, []);

  const handleTextChange = (value, params) => {
    if (!value) {
      const applied = [...appliedFilters]?.filter((a) => a?.type !== "text");
      setAppliedFilters(applied);
      return;
    }

    const search_key = value?.trim()?.toLowerCase();
    const applied = [...appliedFilters];
    if (search_key) {
      const indexOf = applied?.findIndex((a) => a?.type === "text");
      if (indexOf === -1)
        applied.push({ type: "text", params: params, value: search_key });
      else {
        const item = { ...applied[indexOf] };
        item.value = search_key;
        applied[indexOf] = item;
      }
      setAppliedFilters(applied);
    }
  };

  const handleFilter = (e, index, o = null) => {
    e.stopPropagation();
    if (o !== null) {
      const val = o?.value?.trim()?.toLowerCase();

      const actives = val
        ? [...appliedFilters]
        : [...appliedFilters?.filter((a) => a?.param !== o?.param)];

      if (val) {
        const indexOf = actives?.findIndex((a) => a?.param === o?.param);
        if (indexOf === -1)
          actives.push({ type: "select", param: o?.param, value: val });
        else {
          const item = { ...actives[indexOf] };
          item.param = o.param;
          item.value = val;
          actives[indexOf] = item;
        }
      }

      setAppliedFilters(actives);
      setFilterOpened("");
    } else {
      setFilterOpened(index);
    }
  };

  const resetFilters = () => {
    setAppliedFilters([]);
    setFilterOpened("");
  };

  const getTextParamsByType = () => {
    if (type === "tank") return ["name", "batch_name"];
    else if (type === "lot") return ["name", "varieties", "years", "origins"];

    return ["name"]
  }

  const getTextPlaceholderByType = () => {
    if (["tank", "lot"].includes(type)) return getLabel(`search_Placeholder_${type}`);

    getLabel("search_Placeholder");
  }

  return (
    <div className="card_filters">
      <div className="search_wrapper">
        <DebouncedInput
          // forwardRef={ref}
          value={appliedFilters?.find((af) => af?.type === "text")?.value || ""}
          onChange={(value) => handleTextChange(value, getTextParamsByType())}
          onFocus={() => setFilterOpened("")}
          className="input-search"
          placeholder={getTextPlaceholderByType()}
        />
      </div>
      <div className="dropdown_filters" ref={filterRef} onClick={(e) => e.stopPropagation()}>
        {filters?.map((f, index) => (
          <div>
            <div
              className="card_filter"
              onClick={(e) => handleFilter(e, index)}
            >
              {f?.label}
              <span className="">
                <FontAwesomeIcon
                  icon="fa-filter"
                  style={{
                    color:
                      appliedFilters?.findIndex(
                        (af) => af?.param === f?.value
                      ) > -1
                        ? "rgba(100, 36, 46, 1)"
                        : "rgba(100, 36, 46, 0.25)",
                  }}
                  size={"xs"}
                />
              </span>
            </div>
            <div className="dropdown_filter">
              <div
                className={`dropdown-filter-content ${
                  filterOpened === index ? "open" : ""
                }`}
              >
                <div onClick={(e) => e.stopPropagation()}>
                  <ul>
                    <li
                      value={f?.value}
                      onClick={(e) =>
                        handleFilter(e, index, { param: f?.value, value: null })
                      }
                      key={"li-"}
                      className={`all ${f?.label ? "" : "disabled"}`}
                    >
                      {getLabel("filtersAll")} <FontAwesomeIcon icon="remove" />
                    </li>
                    {f?.data?.map((o, i) => {
                      return (
                        <li
                          onClick={(e) => handleFilter(e, index, o)}
                          className={
                            appliedFilters?.findIndex(
                              (af) =>
                                af?.value === o?.value?.trim()?.toLowerCase() &&
                                af?.param === o?.param
                            ) > -1
                              ? "active"
                              : ""
                          }
                          value={f?.label}
                          key={`li-${o?.value}`}
                        >
                          {o?.label}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="filter-box">
        <div className="btn-filter">
          <Tooltip
            variant={"info"}
            html={getLabel("filtersRemoveTooltip")}
            place="left"
            events={["hover"]}
          >
            <CTA
              className="remove"
              disabled={appliedFilters?.length === 0}
              onClick={() => resetFilters()}
            >
              <FontAwesomeIcon icon="fa-filter-circle-xmark" />
            </CTA>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default CardFilters;
