import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./styles/index.scss";

export const SkeletonSwitch = () => {
  return (
    <div className="switch-skeleton">
      <Skeleton width={"100px"} />
      <Skeleton width={"100px"} />
    </div>
  );
};

export const SkeletonTableEdit = ({ colCount = 5, rowsCount = 6, isHeader = true, isMobile = false }) => {
  const columns = [...Array(colCount).keys()].map((i) => {
    return (
      <th key={i}>
        <h1 style={{ margin: "0px" }}>
          <Skeleton />
        </h1>
      </th>
    );
  });

  const rowColumns = [...Array(colCount).keys()].map((i) => {
    return (
      <td key={i}>
        <Skeleton />
      </td>
    );
  });

  const rows = [...Array(rowsCount).keys()].map((i) => {
    return <tr key={i}>{rowColumns}</tr>;
  });

  const mobileFilters = [...Array(3).keys()].map((i) => {
    return <Skeleton key={i} className="mobile-filter-skeleton" />;
  });

  return (
    <div className={`table-skeleton ${!isHeader ? 'no-header' : ''}`}>
      {isHeader && <SkeletonSwitch />}
      {isHeader && <Skeleton
        circle
        height="50px"
        width="50px"
        containerClassName="search-skeleton"
      />}
      {isHeader && <div className="filter-skeleton-div">
        <div className="mobile-filter-skeleton-div">{isMobile && mobileFilters}</div>
        <Skeleton
          circle
          height="50px"
          width="50px"
        />
      </div>}
      {!isMobile && <table width="100%">
        {isHeader && <thead>
          <tr>{columns}</tr>
        </thead>}
        <tbody>{rows}</tbody>
      </table>}
      {isMobile && <SkeletonCard cardCount={rowsCount} />}
    </div>
  );
};

export const SkeletonCard = ({ cardCount = 4}) => {

  const cards = [...Array(cardCount).keys()].map((i) => {
    return <Skeleton key={i} className="card-skeleton" />;
  });

  return (<div className="cards-skeleton">
      {cards}
    </div>)
}

export const SkeletonInfoCard = ({ cardCount = 4}) => {

  const cards = [...Array(cardCount).keys()].map((i) => {
    return <Skeleton key={i} className="info-card-skeleton" />;
  });

  return (<div className="info-cards-skeleton">
    {cards}
  </div>)
}

export const SkeletonNextOperations = ({ colCount = 5, rowsCount = 5 }) => {
  return ( <SkeletonTableEdit colCount={3} rowsCount = {rowsCount} isHeader={false}/>);
};

export const SkeletonAnalysisGrid = ({ title = "", cardCount = 3 }) => {

  const cards = [...Array(cardCount).keys()].map((i) => {
    return <Skeleton className="analysis-grid-item-skeleton" key={i} />;
  });

  return (<div>
      <h4>
      {title}
    </h4>
    <div className="analysis-grid-skeleton">
      {cards}
    </div>
  </div>)
}