import { useEffect, useState } from "react";
import "./styles/index.scss";

const RangeSlider = ({ min, max, current, unit }) => {
  // const [minVal, setMinVal] = useState();
  // const [maxVal, setMaxVal] = useState();
  // const [width, setWidth] = useState();
  const [isValid, setIsValid] = useState();
  const [currentDisplacement, setCurrentDisplacement] = useState(null);
  const [leftDisplacement, setLeftDisplacement] = useState(null);
  const [rightDisplacement, setRightDisplacement] = useState(null);

  useEffect(() => {
    const minValue  = min ?? 0.01;
    const maxValue  = max || current * 2;
    let maxWidth = 0;
    if (current > maxValue) {
      maxWidth = current - minValue;
    } else if (current < minValue) {
      maxWidth = (max || current * 2) - (current < Math.max(min, 0.01) ? current : Math.max(min, 0.01));
    } else if (current >= minValue && current <= maxValue) {
      maxWidth = maxValue - minValue;
    }
    
    let currDispl = 0;
    let leftDispl = 0;
    let rightDispl = 0;

    if (current <= minValue) {
      leftDispl = (minValue * 100) / maxWidth;
      currDispl = (current * 100) / maxWidth;
    } else if (current > maxValue) {
      rightDispl = (minValue * 100) / maxWidth;
      currDispl = rightDispl;
      currDispl = 100 - rightDispl + 10;
    } else if (min === null && max === null) {
      currDispl = 50;
    } else {
      currDispl = (current * 100) / maxWidth;
    }

    setLeftDisplacement(leftDispl);
    setRightDisplacement(rightDispl);
    setCurrentDisplacement(currDispl);
    // setMinVal(minValue);
    // setMaxVal(maxValue);
    // setWidth(maxWidth);

    setIsValid((current < minValue || current > maxValue));
  }, [])
  
  return (
    <div className={`slider-range`}>
      <div
        className="range"
        style={{
          left: `${leftDisplacement}%`,
          width: `${100 - (leftDisplacement || rightDisplacement)}%`,
        }}
      />
      <div className={`current-value ${isValid ? "invalid" : ""}`} style={{ left: `calc(${currentDisplacement}% - 5px)` }}>
        {`${current} ${unit}`}
      </div>
      <div className={`current-point ${isValid ? "invalid" : ""}`} style={{ left: `${currentDisplacement}%` }}>
      </div>
      <div className="min-max" style={{
          width: `${100 - (leftDisplacement || rightDisplacement)}%`, 
          left: `${leftDisplacement}%`,
          right: `${rightDisplacement}%`}
        }>
        <span className="min" style={{ left: `${leftDisplacement}%` }}>{min}</span>
        <span className="max">{max}</span>
      </div>
    </div>
  );
};

export default RangeSlider;
