import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveWinery,
  selectAnalysis,
  selectStoredAnalysisResults,
  selectConfigs,
  selectDrugs,
  selectLots,
  selectOperations,
  selectTanks,
  selectTreatments,
  selectUsers,
  selectUserSockets,
  selectExams,
} from "../../context/selectors";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import SecondaryBar from "../SecondaryBar";
import useLabels from "../../hooks/useLabels";
import { useEffect, useState } from "react";
import {
  Button,
  ConfirmationModal,
  CTA,
  OperationRecap,
  Tooltip,
} from "../Generic";
import {
  addAnalysisSample,
  completeOperation,
  deleteOperation,
  getAnalysisResults,
  getDrugs,
  getExams,
  getLots,
  getOperationById,
  getOperations,
  getOperationsByMainId,
  getPDF,
  getProtocols,
  getRepetitiveOperationsById,
  getTanks,
  getUsers,
  requestPDF,
} from "../../services/utils";
import {
  empty,
  remove,
  setOperations,
} from "../../context/operations/operationsSlice";
import { setUsers } from "../../context/users/usersSlice";
import { emptyTanks, setTanks } from "../../context/tanks/tanksSlice";
import {
  AnalysisDetails,
  AutomaticDetail,
  BottlingDetail,
  CutDetail,
  DestemmingDetail,
  TreatmentDetails,
} from "./Details";
import { ROLES } from "../../constants/base";
import useAuth from "../../hooks/useAuth";
import { setAnalysis } from "../../context/protocols/analysisSlice";
import { setDrugs } from "../../context/substances/drugsSlice";
import { emptyLots, setLots } from "../../context/lots/lotsSlice";
import { setTreatments } from "../../context/protocols/treatmentsSlice";
import {
  addStoredAnalysisResults,
} from "../../context/substances/storedAnalysisResultsSlice";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as XLSX from "xlsx";

import "./styles/index.scss";
import { format } from "date-fns";
import { setExams } from "../../context/substances/examsSlice";

const OperationsDetail = ({ isMobile }) => {
  const [getLabel] = useLabels();
  const activeWinery = useSelector(selectActiveWinery);
  const { id, type } = useParams();
  const operations = useSelector(selectOperations);
  const users = useSelector(selectUsers);
  const tanks = useSelector(selectTanks);
  const lots = useSelector(selectLots);
  const analysis = useSelector(selectAnalysis);
  const drugs = useSelector(selectDrugs);
  const exams = useSelector(selectExams);
  const treatments = useSelector(selectTreatments);
  const configs = useSelector(selectConfigs);
  const userSockets = useSelector(selectUserSockets);
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [operation, setOperation] = useState({});
  const [completedOperations, setCompletedOperations] = useState([]);
  const [analysisResults, setAnalysisResults] = useState([]);
  const [filteredSubstances, setFilteredSubstances] = useState([]);
  const [step, setStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isImport, setIsImport] = useState(false);
  const [isManual, setIsManual] = useState(false);
  const [file, setFile] = useState(null);
  const [analysisState, setAnalysisState] = useState("NOT_SAMPLED"); // [ NOT_SAMPLED, NOT_INSERTED, INSERTING ]
  const pageName = getLabel(`operationTitle${type}`);
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;
  const storedAnalysisResults = useSelector(selectStoredAnalysisResults) || [];
  // true when current user in a USER and is in the cellarmans list for the given operation
  const isCellarmanEnabled =
    operation?.cellarman_ids?.includes(auth?.user_id) && role === 3;

  const {
    register,
    formState: { errors, isValid },
    getValues,
    setValue,
    setError,
    clearErrors,
    control,
  } = useForm({
    defaultValues: {},
    mode: "onTouched",
    reValidateMode: "onTouched",
  });

  const computeAdditionalTypeString = () => {
    if (['CUT', 'DECANT', 'RACKING', 'ANALYSIS', 'TREATMENT', 'DESTEMMING', 'NEW_LOT'].includes(operation?.type)) return '';
    const protocolType = operation?.src_tanks?.[0]?.protocolType || null;
    const homogenizationType = protocolType === 'HOMOGENIZATION_REASSEMBLY' ? operation?.src_tanks?.[0]?.homogenizationType || null : null;
    
    // TO DO: aggiungi cappello o altro in omogenizzazione temperatura
    if (protocolType !== null && protocolType !== 'ADD') return ` (${getLabel(`protocolType_${protocolType}`)}${homogenizationType ? ` ${getLabel(`protocolHomogenizationMass_${homogenizationType}`)}` : ''}) `?.toLowerCase();
    return '';
  };

  const computeRecurrentString = () => {
    if (!["ANALYSIS", "TREATMENT"].includes(operation?.type)) return "";
    const arr = operation?.type === 'TREATMENT' ? treatments : analysis;
    const protocolName = arr?.find((p) => p?.protocols_id === operation?.protocol_id)?.protocols_name || '';
    const pName = protocolName?.length > 0 ? ` [${protocolName}]` : '';
  
    if (!operation?.repetitive) return ` #${operation?.id}${pName}`;
    
    const current = operation?.relative_id || 0;
    const total = operation?.total || 0;

    return current > 0 && total > 0
      ? ` #${operation?.main_id || operation?.id} (${current}/${total})${pName}`
      : "";
  };

  const loadOperations = async () => {
    const currentOperations = await getOperations(axiosPrivate);
    dispatch(setOperations(currentOperations));
    // console.log("currentOperations", currentOperations);
    if (currentOperations && currentOperations?.length > 0 && Object.keys(operation)?.length === 0) {
      // console.log(
      //   "currentOperations 2",
      //   currentOperations.find((operationsDb) => {
      //     return operationsDb.id === Number(id);
      //   }) || {}
      // );
      setOperation(
        currentOperations?.find((operationsDb) => {
          return operationsDb.id === Number(id);
        }) || {}
      );
    }
  };

  const loadUsers = async () => {
    const currentUsers = await getUsers(axiosPrivate);
    dispatch(setUsers(currentUsers));
  };

  const loadTanks = async () => {
    const currentTanks = await getTanks(activeWinery?.id, axiosPrivate);
    dispatch(setTanks(currentTanks));
  };

  const loadDrugs = async () => {
    const currentDrugs = await getDrugs(axiosPrivate);
    dispatch(setDrugs(currentDrugs));
  };

  const loadExams = async () => {
    const currentExams = await getExams(axiosPrivate);
    dispatch(setExams(currentExams));
  };

  const loadTreatments = async () => {
    const currentTreatments = await getProtocols("treatment", axiosPrivate);
    dispatch(setTreatments(currentTreatments));
  };

  const loadLots = async () => {
    const currentLots = await getLots(activeWinery?.id, axiosPrivate);
    dispatch(setLots(currentLots));
  };

  const loadAnalysis = async () => {
    const currentAnalysis = await getProtocols("analysis", axiosPrivate);
    dispatch(setAnalysis(currentAnalysis));
  };

  const loadAnalysisState = async () => {
    if (analysisState !== "NOT_SAMPLED") return;
    if (operation?.completion_date) setAnalysisState("STORED");
    else {
      if (operation?.repetitive) {
        const isSample =
          analysisResults?.[analysisResults?.length - 1]?.results === null;
        if (Number(operation?.relative_id) - Number(operation?.completed) > 1)
          setAnalysisState("NOT_SAMPLED");
        else if (
          isSample &&
          analysisResults?.length >
            operation?.src_tanks?.length * Number(operation?.completed)
        ) {
          const anRes = (await getAnalysisResults([id], axiosPrivate)) || [];
          setAnalysisState("NOT_INSERTED");
        } else setAnalysisState("NOT_SAMPLED");
      } else {
        // console.log('quiii', analysisResults, operation?.src_tanks?.length)
      }
    }
    // console.log(analysisState, 'anStat')
  };

  const customIdInvalidAnalysisResults = 'custom-id-invalid-analysis-results';
  const customIdInvalidSrcTank = 'custom-id-invalid-src-tank';
  const [isInvalidSrcTank, setIsInvalidSrcTank] = useState(false);

  const isSrcTanksStillValid = () => {
    if (!Array.isArray(operation?.src_tanks) || operation?.src_tanks?.length === 0 || operation?.completion_date || lots?.length > 0 || tanks?.length > 0) {
      return;
    }

    let isSrcTankError = false;
    operation?.src_tanks?.forEach((t) => {
      if (["DESTEMMING"].includes(operation?.type)) {
        const lot = lots?.find((ll) => ll?.id === t?.batch_id);
        if (lot === undefined || lot?.actual_quantity <= 0) {
          isSrcTankError = true;
          return false;
        }
      } else if (["NEW_LOT"].includes(operation?.type)) {
        const lot = operation?.src_tanks?.[0];
        if (lot === undefined || !lot?.quantity) {
          isSrcTankError = true;
          return false;
        }
      } else {
        const tank = tanks?.find((tt) => tt?.id === Number(t?.tank_id));
        if (tanks?.length > 0 && (tank === undefined || tank?.quantity <= 0 || !tank?.batch_name)) {
          isSrcTankError = true;
          return false;
        }
      }
    });
      
    if (isSrcTankError) {
      toast(getLabel("toast_srcTankNowEmpty"), {
        toastId: customIdInvalidSrcTank,
        type: toast.TYPE.ERROR,
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsInvalidSrcTank(true);
    } else {
      toast.dismiss(customIdInvalidSrcTank);
    }
  }

  const initData = async () => {
    const promiseArray = [];

    if (operations?.length === 0) {
      promiseArray.push(loadOperations());
    }
    if (users?.length === 0) {
      promiseArray.push(loadUsers());
    }
    if (tanks?.length === 0) {
      promiseArray.push(loadTanks());
    }
    if (lots?.length === 0) {
      promiseArray.push(loadLots());
    }
    if (analysis?.length === 0) {
      promiseArray.push(loadAnalysis());
    }
    if (drugs?.length === 0) {
      promiseArray.push(loadDrugs());
    }
    if (exams?.length === 0) {
      promiseArray.push(loadExams());
    }
    if (treatments?.length === 0) {
      promiseArray.push(loadTreatments());
    }
    promiseArray.push(loadCompletedOperations());
    return Promise.all(promiseArray);
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    loadAnalysisState();
  }, [analysisResults]);


  useEffect(() => {
    if (["TREATMENT", "ANALYSIS"].includes(operation?.type)) filterDisabledSubstances();
  }, [operation, exams, drugs]);

  useEffect(() => {
    // if (operations?.length === 0) {
    //   loadOperations();
    // }
    // if (completedOperations?.length === 0) {
    //   loadCompletedOperations();
    // }

    if (operations && operations?.length > 0 && Object.keys(operation)?.length === 0) {
      setOperation(
        operations.find((operationsDb) => {
          return operationsDb.id === Number(id);
        }) || {}
      );
    }

    if (completedOperations?.length > 0 && Object.keys(operation)?.length === 0) {
      setOperation(
        completedOperations.find((operationsDb) => {
          return operationsDb.id === Number(id);
        }) || {}
      );
    }

    isSrcTanksStillValid();
    // console.log(completedOperations, id)
  }, [id, completedOperations, operation]);

  const loadCompletedOperations = async () => {
    const singleCompletedOperation = await getOperationById(axiosPrivate, id);
    if (
      !singleCompletedOperation ||
      singleCompletedOperation?.length === 0 ||
      singleCompletedOperation?.[0]?.completion_date === null
    ) {
      const analysisInStore =
        storedAnalysisResults?.filter((stored) => stored.operation_id === id) ||
        [];

      if (analysisInStore?.length === 0) {
        const anRes = (await getAnalysisResults([id], axiosPrivate)) || [];
        const anResults = anRes?.sort((a, b) => a?.id - b?.id);

        if (anResults?.length > 0 && anResults?.[0]?.results !== null) {
          setAnalysisResults(anResults);
          dispatch(addStoredAnalysisResults(anResults));
          // console.log('not found')
        } else if (anResults?.length > 0 && anResults?.[0]?.results === null) {
          setAnalysisState("NOT_INSERTED");
          setAnalysisResults(anResults);
        }
      } else {
        // console.log('found')
        setAnalysisResults(analysisInStore || []);
      }
      // return;
    } else if (
      singleCompletedOperation[0]?.type !== "ANALYSIS" &&
      singleCompletedOperation[0]?.type !== "TREATMENT"
    ) {
      setCompletedOperations(singleCompletedOperation);
      // return;
    }
    // else if (singleCompletedOperation[0]?.type !== 'ANALYSIS' && singleCompletedOperation[0]?.type !== 'TREATMENT') return;

    let operation_id = null;
    if (singleCompletedOperation[0]?.repetitive) {
      // get all the related operations
      const relatedOperations = await getOperationsByMainId(axiosPrivate, id);
      const isMain = singleCompletedOperation[0]?.main_id === null;
      operation_id = isMain
        ? id
        : relatedOperations?.find((o) => o.id == id)?.main_id;
      const singleOp =
        (isMain
          ? relatedOperations?.filter((o) => o.id == id)
          : singleCompletedOperation) || null;
      if (singleOp !== null) setCompletedOperations(singleOp);
    } else {
      operation_id = singleCompletedOperation[0]?.id;
      if (operation_id) setCompletedOperations(singleCompletedOperation);
      if (Object.keys(operation)?.length === 0) setOperation(singleCompletedOperation?.[0] || {});
    }

    const analysisInStore =
      operation_id === null
        ? []
        : storedAnalysisResults?.filter(
            (stored) =>
              stored.operation_id === id ||
              stored.operation_id === operation_id[0]
          ) || [];
    // console.log('analysisInStore', analysisInStore)
    // console.log('storedAnalysisResults', storedAnalysisResults)

    if (analysisInStore?.length === 0) {
      const anRes =
        (await getAnalysisResults([operation_id], axiosPrivate)) || [];
      const anResults = anRes?.sort((a, b) => a?.id - b?.id);

      if (anResults?.length > 0 && anResults?.[0]?.results !== null) {
        setAnalysisResults(anResults);
        dispatch(addStoredAnalysisResults(anResults));
        // console.log('not found 2')
      } else if (anResults?.length > 0 && anResults?.[0]?.results === null) {
        setAnalysisState("NOT_INSERTED");
      }
    } else {
      // console.log('found 2')
      setAnalysisResults(analysisInStore || []);
    }
  };

  const filterDisabledSubstances = () => {
    const data = type === 'TREATMENT' ? treatments : analysis;
    const tmp = { ...(data?.find(
      (a) => a.protocols_id == operation?.protocol_id
    ) || {})};

    if ((type === 'ANALYSIS' && !tmp?.analysis) || (type === 'TREATMENT' && !tmp?.drugs)) return {};

    const tmpArray = [];
    const srcArr = [...(type === 'ANALYSIS' ? tmp?.analysis : tmp?.drugs)] || [];
    const srcSubstancesArr = [...(type === 'ANALYSIS' ? exams : drugs)] || [];

    if (Array.isArray(operation?.treatments) && operation?.treatments?.length > 0 && srcArr?.length > 0) {
      // substance_id that have been disable for this application
      const onlyRemoved = srcSubstancesArr
      ?.filter((s) => operation?.treatments?.some((tt) => tt?.substance_id === s?.id && tt?.removed === true))
      ?.map((s) => { return {
        ...s,
        substance_id: s?.id,
        substance_name: s?.name,
        removed: true,
      }});
      
      const onlyNotRemoved = srcSubstancesArr
      ?.filter((s) => operation?.treatments?.some((tt) => tt?.substance_id === s?.id && (!tt?.removed || tt?.removed === false)))
      ?.map((s) => { return {
        ...s,
        substance_id: s?.id,
        substance_name: s?.name
      }});

      tmpArray.push(...onlyRemoved, ...onlyNotRemoved);
    }

    if (type === 'ANALYSIS') {
      tmp.analysis = operation?.treatments?.length > 0 ? tmpArray : srcArr;
    } else {
      tmp.drugs = tmpArray;
    }
    setFilteredSubstances(tmp);
    return tmp;
  }

  const addChatNote = (newNote) => {
    setOperation((prevOperation) => {
      return {
        ...prevOperation,                                   // spread the previous operation object
        chat_notes: Array.isArray(prevOperation.chat_notes)
          ? [...prevOperation.chat_notes, newNote]          // append the new note
          : [newNote]                                       // initialize chat_notes with the new note if it doesn't exist
      };
    });
  }

  const getInfoByType = (type) => {
    switch (type) {
      case "NEW_LOT":
      case "DESTEMMING":
        return (
          <div className="operation_destemming_wrapper">
            <DestemmingDetail
              operation={operation}
              addChatNote={addChatNote}
              users={users}
              tanks={tanks}
              // batchData={lots?.find?.((lot) => {
              //   return Number(lot?.id) === Number(operation?.batch_id_in);
              // })}
              // batchData={operation?.completion_date === null ? operation?.src_tanks?.[0]?.preOperation?.batch : operation?.src_tanks?.[0]?.postOperation?.batch}
              configs={configs}
              register={register}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              axiosPrivate={axiosPrivate}
              dispatch={dispatch}
              user_id={auth?.user_id}
              isNewLot={type === "NEW_LOT"}
              userSockets={userSockets}
              isMobile={isMobile}
            />
          </div>
        );
      // case "CUT":
      //   return (
      //     <div className="operation_cut_wrapper">
      //       <CutDetail
      //         operation={operation}
      //         users={users}
      //         tanks={tanks}
      //         configs={configs}
      //         type={type}
      //         register={register}
      //         errors={errors}
      //         getValues={getValues}
      //       />
      //     </div>
      //   );
      case "CUT":
      case "DECANT":
      case "RACKING":
        return (
          <div className="operation_cut_wrapper">
            <CutDetail
              operation={operation}
              addChatNote={addChatNote}
              users={users}
              tanks={tanks}
              configs={configs}
              type={type}
              register={register}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              axiosPrivate={axiosPrivate}
              dispatch={dispatch}
              user_id={auth?.user_id}
              userSockets={userSockets}
              isMobile={isMobile}
            />
          </div>
        );
      case "TREATMENT":
        return (
          <div className="operation_treatment_wrapper">
            <TreatmentDetails
              operation={operation}
              addChatNote={addChatNote}
              register={register}
              errors={errors}
              users={users}
              tanks={tanks}
              lots={lots}
              drugs={drugs}
              treatments={filteredSubstances}
              configs={configs}
              getValues={getValues}
              setValue={setValue}
              axiosPrivate={axiosPrivate}
              dispatch={dispatch}
              user_id={auth?.user_id}
              userSockets={userSockets}
              isMobile={isMobile}
            />
          </div>
        );
      case "ANALYSIS":
        return (
          <div className="operation_analysis_wrapper">
            <AnalysisDetails
              operation={operation}
              addChatNote={addChatNote}
              register={register}
              users={users}
              tanks={tanks}
              lots={lots}
              configs={configs}
              results={analysisResults?.sort((a, b) => a.id - b.id)}
              analysis={filteredSubstances}
              isCellarmanEnabled={
                isCellarmanEnabled &&
                !(
                  operation?.repetitive &&
                  Number(operation?.completed) <
                    Number(operation?.relative_id) - 1
                )
              }
              registerComplete={register}
              errorsComplete={errors}
              controlComplete={control}
              analysisState={analysisState}
              getValues={getValues}
              setValue={setValue}
              axiosPrivate={axiosPrivate}
              dispatch={dispatch}
              user_id={auth?.user_id}
              userSockets={userSockets}
              isMobile={isMobile}
            />
          </div>
        );
      case "ENZYME":
      case "SO2":
      case "HOMOGENIZATION_MASS":
      case "NITROGEN":
      case "INOCULATION":
      case "BOIS":
      case "TENNISSAGE":
      case "OXYGENATION":
        return (
          <div className="operation_automatic_wrapper">
            <AutomaticDetail
              type={type}
              operation={operation}
              addChatNote={addChatNote}
              users={users}
              tanks={tanks}
              lots={lots}
              drugs={drugs}
              configs={configs}
              register={register}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              axiosPrivate={axiosPrivate}
              dispatch={dispatch}
              user_id={auth?.user_id}
              userSockets={userSockets}
              isMobile={isMobile}
            />
          </div>
        );
      case "BOTTLING":
        return (
          <div className="operation_bottling_wrapper">
            <BottlingDetail
              operation={operation}
              addChatNote={addChatNote}
              users={users}
              tanks={tanks}
              configs={configs}
              register={register}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              axiosPrivate={axiosPrivate}
              dispatch={dispatch}
              user_id={auth?.user_id}
              userSockets={userSockets}
              isMobile={isMobile}
            />
          </div>
        );
      default:
        return (
          <div className="operation_error">{getLabel("genericError")}</div>
        );
    }
  };

  const requestPDF = async () => {
    const data = {
      type: "operationDetail",
      title: getLabel("operationDetail"),
      rows: operations?.map((operation) => operation.id),
      columns: [
        getLabel("priority"),
        getLabel("operationType"),
        getLabel("operationSrcTank"),
        getLabel("operationDestTank"),
        getLabel("cellarman"),
        getLabel("expiringDate"),
        getLabel("operationNotes"),
      ],
      headers: [
        "priority",
        "type",
        "src_tanks",
        "dest_tanks",
        "cellarman_ids",
        "expire_date",
        "note",
      ],
      winery_name: activeWinery?.name || "",
      order_by: "expire_date",
      order_sort: "desc",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language:
        navigator.languages?.length > 0
          ? navigator.languages[0]?.replace("_", "-")
          : "it-IT",
    };

    // await getPDF(data, axiosPrivate);
  };

  const requestLabelPDF = async () => {
    const data = {
      pdf_type: "analysisLabel",
      type: "operations",
      additionalData: {
        subtitle: getLabel("analysisLabel"),
      },
      title: `${activeWinery?.name || ""} AN-${operation?.id}`,
      rows: [operation?.id],
      columns: [
        getLabel("analysisName"),
        getLabel("unit"),
        getLabel("substanceRange"),
      ],
      headers: ["analysis_name", "unit", "analysis_min"],
      winery_name: activeWinery?.name || "",
      order_by: "expire_date",
      order_sort: "desc",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language:
        navigator.languages?.length > 0
          ? navigator.languages[0]?.replace("_", "-")
          : "it-IT",
    };

    await getPDF(
      data,
      axiosPrivate,
      getLabel("pdfType_ANALYSIS") + (operation?.id ? `-${operation?.id}` : "")
    );
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleManualAddOpenModal = () => {
    setIsManual(true);
    handleOpenModal();
  };

  const handleManualAddConfirmModal = () => {
    setIsManual(false);
    handleCloseModal();
    sendData();
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setStep(0);

    if (isManual) setIsManual(false);
  };

  const handleImportConfirmModal = () => {
    if (file === null) {
      setError("file", {
        type: "manual",
        message: getLabel("errorOperationExcelFileImport"),
      });
      return;
    } else {
      if (
        file.type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        setError("file", {
          type: "filetype",
          message: getLabel("errorOperationExcelFileImportFormat"),
        });
        return;
      }
    }

    try {
      importExcel();
    } catch (err) {
      console.log(err);
      setError("file", {
        type: "filetype",
        message: getLabel("errorOperationExcelFileImportError"),
      });
      return;
    }

    if (isImport) {
      setAnalysisState("INSERTING");
      setIsImport(false);
    }
    handleCloseModal();
  };

  const handleFormSubmit = async () => {
    await removeOperation();
    dispatch(empty())
    handleCloseModal();
  };

  const importExcel = () => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const excelData = e.target.result;
        const workbook = XLSX.read(excelData, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);

        const analysisLen = Object.keys(analysis?.[0]?.analysis || {})?.length - operation?.treatments?.length;
        const columnStartIndex = !isImport || !operation?.repetitive
          ? 0
          : analysisLen *
            (operation?.src_tanks?.length || 1) *
            (Number(operation?.completed) || 1);
        const value = getLabel('examResult');
        json?.forEach((j, i) => {
          setValue(`column.${i + columnStartIndex}.value`, j?.[value] || "");
          // console.log('i', i, 'val', j?.[value])
          const min = j?.range?.split("-")?.[0]?.trim() || 0;
          const max = j?.range?.split("-")?.[1]?.trim() || 100000;
          if (Number(j.value) < Number(min) || Number(j.value) > Number(max)) {
            setError(`column.${i + columnStartIndex}.value`, {
              type: "manual",
              message: getLabel("errorDrugQuantityOverMax"),
            });
          }
        });
      };
      reader.readAsBinaryString(file);
    }
  };

  const removeOperation = async () => {
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });

    const response = await deleteOperation(
      operation?.main_id || id,
      axiosPrivate
    );
    const requestPayload = {
      ...(id && { id: operation?.main_id || Number(id) }),
    };

    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { type: operation?.type || "" })
        : getLabel(response?.error),
      type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });
    console.log(response);
    if (response && response?.success) {
      dispatch(remove(requestPayload));
      // dispatch(empty());
      navigate(`/programming/`);
    }
  };

  const renderDetail = () => {
    const infoComponent = getInfoByType(type);
    return <div className="operation_info_container">{infoComponent}</div>;
  };

  const buildAnalysisPayload = (data, requestPayload) => {
    const analysisData = [];
    const results = data?.column?.tanks || [];

    if (results?.length === 0) return [];

    operation?.src_tanks?.forEach((tank, i) => {
      const id = Number(tank?.batch_id || tank?.tank_id);
      if (!id) return [];

      const obj = {
        batch_id: tank?.batch_id,
        tank_id: tank?.tank_id,
        analyst: data?.analyst,
        temperature: data?.temperature?.[id] || null,
      };
      // obj.collection_date = (new Date()).toISOString();
      // obj.start_date = addMinutes(new Date(data?.start_date), -new Date(data?.start_date).getTimezoneOffset()).toISOString();
      // obj.end_date = addMinutes(new Date(data?.end_date), -new Date(data?.end_date).getTimezoneOffset()).toISOString();

      const currentTankAnalysisData = results?.filter(t => t?.tank_id == id);
      if (currentTankAnalysisData?.length === 0 ||
        currentTankAnalysisData?.length !== filteredSubstances?.substance_ids?.length || 
        currentTankAnalysisData?.filter(item => Number(item?.value) === 0)?.length > 0) {
          const currentTankName = tanks?.find(tt => tt.id === tank?.tank_id)?.name || ""
          toast.error(getLabel("toast_analysisMissingPerTank", {tank_name: currentTankName}), {
            toastId: customIdInvalidAnalysisResults,
            isLoading: false,
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 4000,
        });
          return;
        };
      
      obj.results = currentTankAnalysisData;
      // const currentTankResults = ;

      // res
      //   // .slice(i * chosenAnalysis?.length, (i + 1) * chosenAnalysis?.length)
      //   ?.filter(r => r?.tank_id === id)
      //   ?.forEach((r, index) => {
      //     const key = chosenAnalysis[index]?.substance_name;
      //     if (key) {
      //       results[key] = parseFloat(r?.value);

      //       if (operation?.on_tank) obj.tank_id = r?.tank_id;
      //       else obj.batch_id = r?.batch_id;
      //     }
      //   });
      // obj.results = results;

      analysisData.push(obj);
    });
    requestPayload.analysis = analysisData;
  };

  const buildDestemmingPayload = (data, requestPayload) => {
    const drops = [];

    data?.drop?.forEach((d, index) => {
      if (d) {
        drops.push({ tank_id: index, quantity: Number(d) || 0 });
      }
    });

    requestPayload.drop = drops;
  };

  const sendData = async () => {
    const data = getValues();
    // console.log(data, "dataSend", errors);
    
    const requestPayload = {};
    if (operation?.type === "ANALYSIS") {
      console.log("dataSend", errors);
      const errorsNoWarning = (errors?.column || [])?.filter(e => e?.value?.type !== "min" && e?.value?.type !== "max")?.length;  // exclude min/max warnings
      if (errorsNoWarning?.length > 0) return;
      buildAnalysisPayload(data, requestPayload);
    } else if (
      ["DESTEMMING", "CUT", "DECANT", "RACKING", "NEW_LOT", "BOTTLING"].includes(
        operation?.type
      )
    ) {
      if (Object.keys(errors)?.length > 0) return;
      buildDestemmingPayload(data, requestPayload);
    }
    console.log('requestPayload', requestPayload);
    // return;
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });

    const response = await completeOperation(id, requestPayload, axiosPrivate);

    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { type: operation?.type || "" })
        : getLabel(response?.error),
      type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });
    console.log(response);
    if (response && response?.success) {
      dispatch(remove(requestPayload));
      if (operation?.type === "ANALYSIS") {
        // dispatch(emptyExams());
      } else if (
        ["DESTEMMING", "CUT", "RACKING", "DECANT", "NEW_LOT", "BOTTLING"].includes(
          operation?.type
        )
      ) {
        dispatch(emptyLots());
        dispatch(emptyTanks());
      }

      navigate(`/programming/`);
    }
  };

  const sendSampleData = async () => {
    const data = getValues();
    
    const requestPayload = {};
    buildAnalysisPayload(data, requestPayload);
    console.log(requestPayload, 'sample');
    // return;
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });
    
    const response = await addAnalysisSample(id, requestPayload, axiosPrivate);

    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { type: operation?.type || "" })
        : getLabel(response?.error),
      type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });
    console.log(response);
    if (response && response?.success) {
      dispatch(remove(requestPayload));
      setIsOpen(false);
      setAnalysisState("NOT_INSERTED");
      // const anResults = (await getAnalysisResults([operation?.main_id || operation?.id], axiosPrivate) || []);
      // const anRes = (await getAnalysisResults([operation?.main_id || operation?.id], axiosPrivate) || []);
      // const anResults = anRes?.sort((a, b) => a?.id - b?.id);
      loadCompletedOperations();
      // console.log(anResults, anResults?.length, operation?.src_tanks?.length, '----');
      // if (anResults?.length === operation?.src_tanks?.length)
      //   setAnalysisState("NOT_INSERTED");
      // navigate(`/programming/`);
    }
  };

  const renderInsertAnalysisSample = () => {
    // const isCompleted = true;
    const isCurrentIterationDisabled =
      Number(operation?.relative_id) - Number(operation?.completed) > 1;
    const disabled =
      isCurrentIterationDisabled ||
      (analysisResults?.length <
        Number(operation?.completed) * operation?.src_tanks?.length &&
        (!analysisResults?.results || analysisResults?.results?.length === 0));

    if (disabled)
      return (
        <Tooltip
          variant={"info"}
          html={isInvalidSrcTank ? getLabel("errorSrcTankNowEmpty")
            : getLabel("errorPreviousRecurrOpsNotCompleted")}
          place="right"
          events={["hover"]}
        >
          <CTA
            customClassName={analysisState === "INSERTING" ? "complete" : "add"}
            disabled={disabled}
          >
            {getLabel("addRepetitiveAnalysisSampleOperation")}
          </CTA>
        </Tooltip>
      );
    else
      return (
        <CTA
          customClassName={analysisState === "INSERTING" ? "complete" : "add"}
          onClick={() =>
            operation?.type === "ANALYSIS" ? handleOpen() : sendSampleData()
          }
          disabled={disabled}
        >
          {analysisState === "NOT_SAMPLED"
            ? getLabel("addRepetitiveAnalysisSampleOperation")
            : getLabel("addRepetitiveAnalysisOperation")}
        </CTA>
      );
  };

  const handleImport = () => {
    setIsImport(true);
    handleOpenModal();
  };

  const cancel = () => {
    const column = getValues()?.column?.map((c) => {
      return { value: "" };
    });
    setValue("column", column);
    if (analysisState === "INSERTING") setAnalysisState("NOT_INSERTED");
  };

  const handleExport = () => {
    const data = [];
    operation?.src_tanks?.forEach((tank) => {
      const src = tanks?.find((t) => t?.id == tank?.tank_id);
      analysis?.[0]?.analysis?.forEach((an) => {
        if (operation?.treatments?.find((s) => s?.substance_id == an?.substance_id && s?.removed)) return true;
        const tmp = {};
        tmp[getLabel('tank')] = src?.name;
        tmp[getLabel('tank_id')] = src?.id;
        tmp[getLabel('analysisSubstanceId')] = an?.substance_id;
        tmp[getLabel('analysisSubstanceName')] = getLabel(`substance_${an?.substance_name}`);
        tmp[getLabel('substanceRang')] = parseInt(an?.min_range) >= 0 && parseInt(an?.max_range) >= 0
                  ? `${String(an?.min_range) || ""} - ${
                      String(an?.max_range) || ""
                    }`
                  : "";
        tmp[getLabel('unit')] = an?.unit;
        tmp[getLabel('analysisLabel_id')] =  `AN-${operation?.id} ${src?.name}`;
        tmp[getLabel('examResult')] = "";
        
        data.push(tmp);
      });
    });
    // console.log(data)
    // return;

    const worksheet = XLSX.utils.json_to_sheet(data);
    const wscols = [
      {wch: 13},
      {wch: 15},
      {wch: 10},
      {wch: 10},
      {wch: 13},
      {wch: 13},
      {wch: 13},
      {wch: 16},
      {wch: 15},
    ];
    worksheet['!cols'] = wscols;
    
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `${getLabel('pdfType_ANALYSIS')}-${id}_${format(new Date(), configs.shortDateFormat)}.xlsx`
    );
  };

  const rendereUpdateButton = () => {

    if (!operation.repetitive ||
      (operation?.repetitive &&
        !operation.main_id &&
        Number(operation?.relative_id) === 1 &&
        Number(operation?.completed) === 0)) return (
      <NavLink to={`/programming/${type}/${id}/update`}>
        <CTA>{getLabel("updateOperation")}</CTA>
      </NavLink>)

    const isRepetitiveAndNotStarted = operation?.repetitive &&
      operation.main_id &&
      Number(operation?.completed) === 0;
    const isRepetitiveAndAlreadyStartedDisable = operation?.repetitive &&
      operation.main_id &&
      Number(operation?.completed) > 0;
    
    const disabled = isRepetitiveAndAlreadyStartedDisable;

    if (disabled)
      return (
      <Tooltip
        variant={"info"}
        html={
          isRepetitiveAndAlreadyStartedDisable ? getLabel("errorRecurrentOpAlreadyStarted")
          : ""
        }
        place="right"
        events={["hover"]}
      >
      <NavLink to={`/programming/${type}/${isRepetitiveAndNotStarted ? operation?.main_id : id}/update`}>
        <CTA disabled={disabled}>{getLabel("updateOperation")}</CTA>
      </NavLink>
      </Tooltip>
    )
    return (
      <NavLink to={`/programming/${type}/${isRepetitiveAndNotStarted ? operation?.main_id : id}/update`}>
        <CTA>{getLabel("updateOperation")}</CTA>
      </NavLink>)
  }

  const renderCompleteButton = () => {
    const analysisResultsPerOperationId = analysisResults?.filter(
      (an) => an?.operation_id === operation?.id
    );
    // console.log('analysisResultsPerOperationId', analysisResultsPerOperationId, analysisState, analysisResults)
    if (
      ["NOT_SAMPLED"]?.includes(analysisState) &&
      operation?.type === "ANALYSIS" &&
      // operation?.repetitive &&
      (analysisResultsPerOperationId?.length < operation?.src_tanks?.length ||
        analysisResultsPerOperationId?.length === 0)
    ) {
      return renderInsertAnalysisSample();
    }
    const repetitiveDisabled =
      operation?.repetitive &&
      Number(operation?.completed) < Number(operation?.relative_id) - 1;
    const checkboxDisabled = operation?.type === 'BOTTLING' ? getValues("completed")?.[operation?.src_tanks?.[0]?.tank_id] === false : !(getValues("completed") || []).every(
      (v) => v === true
    );
    const dropDisabled =
      operation?.type === "DESTEMMING" &&
      (getValues("drop")?.filter((c) => c) || [])?.length <
        operation?.dest_tanks?.length;
    const analysisNum =
      analysisState === "INSERTING"
        ? analysis?.find((a) => a.protocols_id == operation?.protocol_id)
            ?.analysis?.length || 0
        : 0;

    const repetitiveIterationDisabled =
      analysisState === "INSERTING"
        ? operation?.repetitive &&
          ((getValues("column")?.filter((c) => c.value) || []).length <
            analysisNum ||
            (errors?.column?.filter((c) => c.value) || []).length > 0)
        : false;

    const isCompleted = operation?.completion_date !== null;
    const disabled =
      isCompleted ||
      repetitiveDisabled ||
      checkboxDisabled ||
      dropDisabled ||
      repetitiveIterationDisabled;

    if (disabled)
      return (
        <>
          {operation?.type === "ANALYSIS" && analysisState === "INSERTING" && (
            <CTA onClick={cancel} disabled={false}>
              {getLabel("deny")}
            </CTA>
          )}
          {operation?.type === "ANALYSIS" && (
            <Tooltip
              variant={"info"}
              html={
                isInvalidSrcTank ? getLabel("errorSrcTankNowEmpty")
                : isCompleted
                  ? getLabel("errorOpsCompleted")
                  : repetitiveDisabled
                  ? getLabel("errorPreviousRecurrOpsNotCompleted")
                  : checkboxDisabled
                  ? getLabel("errorNotAllCheckboxCompleted")
                  : repetitiveIterationDisabled
                  ? getLabel("errorNotAllAnalysisCompleted")
                  : ""
              }
              place="right"
              events={["hover"]}
            >
              {operation?.on_tank && (
                <CTA customClassName={"complete"} disabled={disabled}>
                  {getLabel("addRepetitiveAnalysisOperationExcel")}
                </CTA>
              )}
            </Tooltip>
          )}
          <Tooltip
            variant={"info"}
            html={
              isInvalidSrcTank ? getLabel("errorSrcTankNowEmpty")
              : isCompleted
                ? getLabel("errorOpsCompleted")
                : repetitiveDisabled
                ? getLabel("errorPreviousRecurrOpsNotCompleted")
                : checkboxDisabled
                ? getLabel("errorNotAllCheckboxCompleted")
                : repetitiveIterationDisabled
                ? getLabel("errorNotAllAnalysisCompleted")
                : ""
            }
            place="right"
            events={["hover"]}
          >
            <CTA customClassName={"complete"} disabled={disabled}>
              {operation?.type === "ANALYSIS"
                ? getLabel("completeRepetitiveAnalysisOperation")
                : getLabel("completeOperation")}
            </CTA>
          </Tooltip>
        </>
      );
    else
      return (
        <>
          {operation?.type === "ANALYSIS" && analysisState === "INSERTING" && (
            <CTA onClick={cancel} disabled={false}>
              {getLabel("deny")}
            </CTA>
          )}
          {operation?.type === "ANALYSIS" && operation?.on_tank && (
            <CTA
              onClick={handleImport}
              customClassName={
                analysisState === "INSERTING" ? "complete" : "add"
              }
              disabled={analysisState !== "NOT_INSERTED"}
            >
              {getLabel("addRepetitiveAnalysisOperationExcel")}
            </CTA>
          )}
          <CTA
            customClassName={analysisState === "INSERTING" ? "complete" : "add"}
            onClick={() =>
              operation?.type === "ANALYSIS" ? handleOpen() : sendData()
            }
            disabled={disabled}
          >
            {operation?.type === "ANALYSIS"
              ? analysisState === "INSERTING"
                ? getLabel("completeRepetitiveAnalysisOperation")
                : getLabel("addRepetitiveAnalysisOperation")
              : getLabel("completeOperation")}
          </CTA>
          {operation?.type === "ANALYSIS" && operation?.on_tank && (
            <CTA onClick={requestLabelPDF}>
              {getLabel("printAnalysisOperationLabel")}
            </CTA>
          )}
        </>
      );
  };

  const handleOpen = () => {
    if (analysisState === "NOT_SAMPLED") {
      handleOpenModal();
      // setAnalysisState('NOT_INSERTED');
    } else if (analysisState === "NOT_INSERTED") {
      setAnalysisState("INSERTING");
    } else if (analysisState === "INSERTING") {
      handleManualAddOpenModal();
    }
    //  else if (analysisState === "INSERTED") {
    //   sendData();
    // }
  };

  const renderNavMenu = () => {
    const navClickHandler = () => {
      window.history.back();
    };
    return (
      <div className="primary_container_menu">
        <div className="primary_container_row">
          <Button arrowDirection="left" onClick={navClickHandler} />
          <div className="title_container">
            <h2>
              {pageName +
                computeAdditionalTypeString() +
                computeRecurrentString()}
            </h2>
          </div>
        </div>
        <div className="primary_container_row ctas">
          {operation?.completion_date === null &&
            (role === ROLES.USER ||
              (role === ROLES.CLIENT &&
                users?.filter((u) => u?.type === "USER")?.length === 0)) &&
            // ["ANALYSIS", "TREATMENT"].includes(operation?.type) &&
            // operation?.completion_date === null &&
            renderCompleteButton()}
          {role === ROLES.CLIENT && operation.completion_date === null && (
            <>
            {rendereUpdateButton()}
              <NavLink to={`/programming/${type}/${id}/duplicate`}>
                <CTA>{getLabel("copyOperation")}</CTA>
              </NavLink>

              <CTA onClick={handleOpenModal}>{getLabel("deleteOperation")}</CTA>
              <ConfirmationModal
                isOpen={isOpen}
                onConfirm={handleFormSubmit}
                onClose={handleCloseModal}
                description={getLabel(
                  operation?.repetitive
                    ? "modalDeleteRepetitiveOperationDescription"
                    : "modalDeleteOperationDescription",
                  {
                    type: getLabel("operationType" + operation?.type),
                  }
                )}
              ></ConfirmationModal>
            </>
          )}
          {role === ROLES.USER &&
            operation.completion_date === null &&
            ["ANALYSIS", "TREATMENT"].includes(operation?.type) && (
              <>
                <ConfirmationModal
                  isOpen={isOpen}
                  onConfirm={
                    isManual
                      ? handleManualAddConfirmModal
                      : !isImport
                      ? sendSampleData
                      : handleImportConfirmModal
                  }
                  onClose={handleCloseModal}
                  description={
                    isManual
                      ? getLabel("modalNewAnalysisAnalystAdditional")
                      : !isImport
                      ? getLabel("modalNewAnalysisExamAdditional")
                      : getLabel("modalNewAnalysisExamExcelAdditional")
                  }
                  isOperationRecap={true}
                  errors={errors}
                  getValues={getValues}
                  isAnalysis={true}
                  setError={setError}
                  clearErrors={clearErrors}
                  totalStep={
                    isManual
                      ? 0
                      : !isImport
                      ? operation?.src_tanks?.length - 1
                      : 0
                  }
                  step={step}
                  setStep={setStep}
                  type={
                    isManual ? "ANALYST" : !isImport ? "" : "EXCEL_ANALYSIS"
                  }
                >
                  <OperationRecap
                    type={
                      isManual
                        ? "ANALYST"
                        : !isImport
                        ? "ANALYSIS"
                        : "EXCEL_ANALYSIS"
                    }
                    control={control}
                    register={register}
                    getValues={getValues}
                    setValue={setValue}
                    setError={setError}
                    errors={errors}
                    configs={configs}
                    lots={lots}
                    tanks={tanks}
                    totalStep={operation?.src_tanks?.length - 1}
                    step={step}
                    src_tanks={operation?.src_tanks}
                    setFile={setFile}
                    columnStartIndex={
                      !isImport
                        ? 0
                        : Object.keys(analysis?.[0]?.analysis || {})?.length *
                          (operation?.src_tanks?.length || 1)
                    }
                  />
                </ConfirmationModal>
              </>
            )}
          <CTA onClick={requestPDF}>{getLabel("print")}</CTA>
          {operation.completion_date === null &&
            operation?.type === "ANALYSIS" && (
              <CTA onClick={handleExport}>
                {getLabel("operationExcelExport")}
              </CTA>
            )}
        </div>
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar breadCrumb={[getLabel("programmingNavLink"), pageName]} />
      {renderNavMenu()}
      {renderDetail()}
    </div>
  );
};

export default OperationsDetail;
